import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import {
  Badge,
  Col,
  Collapse,
  Dropdown,
  Form as AntForm,
  Form,
  Icon,
  Menu,
  Popconfirm,
  Radio,
  Row,
  Table,
} from 'antd'
import fileDownload from 'js-file-download'
import { has, isEmpty, map, omit, values } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  Breadcrumb,
  FormDate,
  FormInternalLink,
  FormLink,
  FormText,
  HiddenCheckboxes,
  HiddenInputs,
  Select,
  TextArea,
  Upload,
} from '../../../core/components'
import { Button } from '../../../core/components/styled'
import { THEME } from '../../../core/constants'

import {
  HistoryDrawer,
  HtmlMenuScrapingHistoryDrawer,
  Spinner,
  VisibilityContainer,
} from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import {
  ADMIN,
  MENU_SCRAPING_TASK,
  MENU_SCRAPING_TASKS_PERMISSIONS,
  TEAM_LEADER,
} from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useUserHasRoles } from '../../../common/hooks'
import { getCachedFilterForPage } from '../../../common/services'

import { AuthContext } from '../../../account/contexts'
import {
  AUTO_REJECTED,
  HTML,
  HTML_NOT_SCRAPEABLE,
  MENU_CATEGORIES,
  MENU_FORMATS,
  MENU_NOT_SCRAPEABLE_REASONS,
  MENU_STATUSES,
  PDF,
} from '../../../menus/constants'
import { MENU_PDF_FILE_URL } from '../../../menus/graphql/queries'
import { isMenuScrapeable } from '../../../menus/helpers'
import {
  MANUAL_CHANGE,
  MENU_SCRAPING,
  REOPENED,
  RESOLVED,
  TASK_AWAITING_REASONS,
  TASK_REOPEN_REASONS,
} from '../../constants'
import {
  ASSIGN_MENU_SCRAPING_TASK,
  MARK_MENU_SCRAPING_TASK_AS_AWAITING,
  MARK_MENU_SCRAPING_TASK_MISC_AS_QC_VERIFIED,
  MARK_MENU_SCRAPING_TASK_MISC_AS_VERIFIED,
  REOPEN_MENU_SCRAPING_TASK,
  RESOLVE_MENU_SCRAPING_TASK_BY_RECRAWL_PDF_MENU,
  UNASSIGN_MENU_SCRAPING_TASK,
  UNMARK_MENU_SCRAPING_TASK_AS_AWAITING,
} from '../../graphql/mutations'
import { getBreadcrumbItemsForMenuScrapingTaskPage } from '../../helpers'
import { useCanUserReopenMenuScrapingTask } from '../../hooks'
import {
  AwaitingReasonField,
  LocationsList,
  MarkMiscTaskAsVerifiedModal,
  MiscTaskVerificationThreeStateSwitch,
  RejectPdfMenuCrawlingModal,
  TaskActionSection,
} from '../pages/sections'

import { menuScrapingTaskFormInitialValues } from './initial-values'
import {
  resolveMenuScrapingTaskSchema,
  saveMenuScrapingTaskSchema,
} from './schemas'
import { withSchemaValidator } from './validators'

const { Group: ButtonGroup } = Button

const PdfMenuScrapingTaskForm = ({
  task,
  isSavingTask,
  isResolvingTask,
  onSubmit,
}) => {
  const { user: loggedInUser } = useContext(AuthContext)

  const [isQCVerified, setIsQCVerified] = useState(false)
  const history = useHistory()

  const [
    isMarkMiscTaskAsVerifiedModalOpen,
    setIsMarkMiscTaskAsVerifiedModalOpen,
  ] = useState(false)

  const [isRejectMenuModalOpen, setIsRejectMenuModalOpen] = useState(false)

  const formInitialValues = useMemo(
    () => menuScrapingTaskFormInitialValues(task),
    [task],
  )

  const formMethods = useForm({
    defaultValues: formInitialValues,
    resolver: yupResolver(saveMenuScrapingTaskSchema),
  })

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    errors,
    setError,
    clearErrors,
  } = formMethods

  const isScrapeable = isMenuScrapeable(watch('menu.format'))
  const miscTaskVerifiedComment = watch('task.miscTaskVerifiedComment')
  const miscTaskQCVerifiedComment = watch('task.miscTaskQCVerifiedComment')

  const { menu } = task

  const isAdmin = useUserHasRoles(ADMIN)
  const isTeamLeader = useUserHasRoles(TEAM_LEADER)

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [initialMenuFormat, setInitialMenuFormat] = useState(menu.format)

  const [
    initialMenuNotScrapeableReason,
    setInitialMenuNotScrapeableReason,
  ] = useState(menu.notScrapeableReason)

  const [
    initialMenuNotScrapeableComment,
    setInitialMenuNotScrapeableComment,
  ] = useState(menu.notScrapeableComment)

  const taskStatus = watch('task.status')
  const menuFormat = watch('menu.format')
  const menuNotScrapeableReason = watch('menu.notScrapeableReason')
  const menuNotScrapeableComment = watch('menu.notScrapeableComment')
  const menuPdfFiles = watch('menuPdfFiles')

  const normalizeValue = value => value ?? ''

  useEffect(() => {
    setValue('menu.status', menu.status && MENU_STATUSES[menu.status].name)
    setValue('menu.modifiedDate', menu.modifiedDate)
    setInitialMenuFormat(menu.format)
    setInitialMenuNotScrapeableReason(menu.notScrapeableReason)
    setInitialMenuNotScrapeableComment(menu.notScrapeableComment)
  }, [menu, setValue])

  useEffect(() => {
    const normalizedMenuNotScrapeableComment = normalizeValue(
      menuNotScrapeableComment,
    )

    const normalizedInitialMenuNotScrapeableComment = normalizeValue(
      initialMenuNotScrapeableComment,
    )

    if (
      menuFormat !== initialMenuFormat ||
      menuNotScrapeableReason !== initialMenuNotScrapeableReason ||
      normalizedMenuNotScrapeableComment !==
        normalizedInitialMenuNotScrapeableComment
    ) {
      setIsSaveButtonDisabled(false)
    } else {
      setIsSaveButtonDisabled(true)
    }
  }, [
    menuFormat,
    initialMenuFormat,
    menuNotScrapeableReason,
    initialMenuNotScrapeableReason,
    menuNotScrapeableComment,
    initialMenuNotScrapeableComment,
    getValues,
  ])

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_MENU_SCRAPING_TASK,
    {
      onCompleted({ assignMenuScrapingTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: assignMenuScrapingTask.modifiedDate,
          isAssigned: true,
          assignedTo: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Menu scraping task assigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping task assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_MENU_SCRAPING_TASK,
    {
      onCompleted({ unassignMenuScrapingTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: unassignMenuScrapingTask.modifiedDate,
          isAssigned: false,
          assignedTo: {
            id: '',
            name: '',
          },
        }

        setValue('task', updatedTask)
        showSuccessNotification({
          message: 'Menu scraping task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping task unassign failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsAwaiting,
    { loading: isMarkingTaskAsAwaiting },
  ] = useMutation(MARK_MENU_SCRAPING_TASK_AS_AWAITING, {
    onCompleted({ markMenuScrapingTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: markMenuScrapingTaskAsAwaiting.modifiedDate,
        isAwaiting: true,
        awaitingReason:
          TASK_AWAITING_REASONS[markMenuScrapingTaskAsAwaiting.awaitingReason]
            .name,
        awaitingReasonComment:
          markMenuScrapingTaskAsAwaiting.awaitingReasonComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu scraping task marked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping task mark as awaiting failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsAwaiting,
    { loading: isUnmarkingTaskAsAwaiting },
  ] = useMutation(UNMARK_MENU_SCRAPING_TASK_AS_AWAITING, {
    onCompleted({ unmarkMenuScrapingTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: unmarkMenuScrapingTaskAsAwaiting.modifiedDate,
        isAwaiting: false,
        awaitingReason: '',
        awaitingReasonComment: '',
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu scraping task unmarked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu scraping task unmark as awaiting failed.',
        description: message,
      })
    },
  })

  const [reopenTask, { loading: isReopeningTask }] = useMutation(
    REOPEN_MENU_SCRAPING_TASK,
    {
      onCompleted({ reopenMenuScrapingTask: { modifiedDate, reopenedDate } }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate,
          verifiedBy: {
            id: null,
            name: null,
          },
          verifiedDate: null,
          reopenedBy: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
          reopenedDate,
          reopenReason: TASK_REOPEN_REASONS[MANUAL_CHANGE].name,
          status: REOPENED,
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Menu scraping task reopened.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu scraping task reopen failed.',
          description: message,
        })
      },
    },
  )

  const [
    markMiscTaskAsVerified,
    { loading: isMarkingMiscTaskAsVerified },
  ] = useMutation(MARK_MENU_SCRAPING_TASK_MISC_AS_VERIFIED, {
    onCompleted({
      markMenuScrapingTaskMiscAsVerified: {
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskVerifiedBy: {
          id: loggedInUser.id,
          name: loggedInUser.name,
        },
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as verified failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsQCVerified,
    { loading: isMarkingMiscTaskAsQCVerified },
  ] = useMutation(MARK_MENU_SCRAPING_TASK_MISC_AS_QC_VERIFIED, {
    onCompleted({
      markMenuScrapingTaskMiscAsQCVerified: {
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskQCVerifiedBy: {
          id: loggedInUser.id,
          name: loggedInUser.name,
        },
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as QC verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as QC verified failed.',
        description: message,
      })
    },
  })

  const [getMenuPdfFile, { data: { menuPdfFileUrl } = {} }] = useLazyQuery(
    MENU_PDF_FILE_URL,
    {
      fetchPolicy: 'no-cache',
      onCompleted() {
        window.open(menuPdfFileUrl, '_blank')
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Download menu PDF failed.',
          description: message,
        })
      },
    },
  )

  const onAssignTask = useCallback(
    () =>
      assignTask({
        variables: { id: task.id, toUserId: loggedInUser.id },
      }),
    [assignTask, task, loggedInUser],
  )

  const onUnassignTask = useCallback(
    () => unassignTask({ variables: { id: task.id } }),
    [unassignTask, task],
  )

  const onMarkTaskAsAwaiting = useCallback(
    awaitingReasonData =>
      markTaskAsAwaiting({
        variables: { id: task.id, ...awaitingReasonData },
      }),
    [markTaskAsAwaiting, task],
  )

  const onUnmarkTaskAsAwaiting = useCallback(() => {
    unmarkTaskAsAwaiting({ variables: { id: task.id } })
  }, [unmarkTaskAsAwaiting, task])

  const onReopenTask = useCallback(
    () => reopenTask({ variables: { id: task.id } }),
    [reopenTask, task],
  )

  const onVerifiedButtonClick = () => {
    setIsQCVerified(false)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const onQCVerifiedButtonClick = () => {
    setIsQCVerified(true)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const [
    resolveTaskByRecrawlMenu,
    { loading: isResolvingTaskByRecrawlingMenu },
  ] = useMutation(RESOLVE_MENU_SCRAPING_TASK_BY_RECRAWL_PDF_MENU, {
    onCompleted() {
      showSuccessNotification({
        message: 'Dish review task resolved.',
        description: 'Dish review was task resolved by rescraping menu.',
      })
      history.push(getCachedFilterForPage('/tasks/menu-scraping'))
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Dish review task unassign failed.',
        description: message,
      })
    },
  })

  const recrawlMenu = useCallback(
    useFallbackSettings =>
      resolveTaskByRecrawlMenu({
        variables: {
          id: task.id,
          useFallbackSettings,
        },
      }),
    [resolveTaskByRecrawlMenu, task.id],
  )

  const handleMarkMiscTaskAsVerified = useCallback(
    ({ comment }) => {
      const data = { variables: { id: task.id, comment } }
      setIsMarkMiscTaskAsVerifiedModalOpen(false)

      return isQCVerified
        ? markMiscTaskAsQCVerified(data)
        : markMiscTaskAsVerified(data)
    },
    [isQCVerified, markMiscTaskAsVerified, markMiscTaskAsQCVerified, task],
  )

  const onBrandMenuFormatChange = useCallback(
    format => {
      if (!isMenuScrapeable(format)) {
        setValue('menu.scrapingInstructions', null)
      }

      if (format !== HTML_NOT_SCRAPEABLE) {
        setValue('menu.notScrapeableReason', null)
      }

      if (format !== HTML_NOT_SCRAPEABLE) {
        setValue('menu.notScrapeableComment', null)
      }
    },
    [setValue],
  )

  const onMenuPdfFileNameClick = useCallback(
    menuPdfFile => {
      getMenuPdfFile({
        variables: {
          fileBucketName: menuPdfFile.fileBucketName,
          fileKey: menuPdfFile.fileKey,
        },
      })
    },
    [getMenuPdfFile],
  )

  const onMenuPdfFileSelect = useCallback(
    selectedIndex => {
      const { menuPdfFiles } = getValues()

      const updatedMenuPdfFiles = map(menuPdfFiles, (pdfFile, index) => ({
        ...pdfFile,
        isSelected: index === selectedIndex && !pdfFile.isSelected,
      }))

      setValue('menuPdfFiles', updatedMenuPdfFiles)
    },
    [getValues, setValue],
  )

  const pageTitle = useMemo(
    () =>
      menuFormat === PDF ? 'PDF Menu Scraping Task' : 'Menu Scraping Task',
    [menuFormat],
  )

  const isAttachedToCluster = useMemo(() => !!menu.menuScrapingClusterId, [
    menu,
  ])

  const isRejectButtonDisabled = useMemo(
    () => taskStatus === RESOLVED || menu.status === AUTO_REJECTED,
    [menu.status, taskStatus],
  )

  const isRecrawlButtonDisabled = useMemo(() => taskStatus === RESOLVED, [
    taskStatus,
  ])

  const menuFormatOptions = values(omit(MENU_FORMATS, [HTML]))

  const notScrapableReasonOptions = useMemo(
    () => values(MENU_NOT_SCRAPEABLE_REASONS),
    [],
  )

  const onSave = useCallback(async () => {
    setValue('task.isDone', false)
    const formValues = getValues()

    const { isValid, errors } = await withSchemaValidator(
      formValues,
      saveMenuScrapingTaskSchema,
    )

    if (isValid) {
      clearErrors()
      handleSubmit(formValues => onSubmit(formValues))()
    } else {
      Object.keys(errors).forEach(field => {
        setError(field, { type: 'manual', message: errors[field] })
      })
    }
  }, [setValue, getValues, handleSubmit, onSubmit, setError, clearErrors])

  const onDone = useCallback(async () => {
    setValue('task.isDone', true)
    const formValues = getValues()

    const { isValid, errors } = await withSchemaValidator(
      formValues,
      resolveMenuScrapingTaskSchema,
    )

    if (isValid) {
      clearErrors()
      handleSubmit(formValues => onSubmit(formValues))()
    } else {
      Object.keys(errors).forEach(field => {
        setError(field, { type: 'manual', message: errors[field] })
      })
    }
  }, [setValue, getValues, handleSubmit, onSubmit, setError, clearErrors])

  const canReopenTask = useCanUserReopenMenuScrapingTask(
    loggedInUser,
    watch('task'),
  )

  const breadcrumbItems = useMemo(
    () => getBreadcrumbItemsForMenuScrapingTaskPage(task),
    [task],
  )

  const menuPdfFilesTableColumns = [
    {
      dataIndex: 'isSelected',
      width: '3%',
      render: (text, record, index) => (
        <Radio
          checked={record.isSelected}
          onClick={() => onMenuPdfFileSelect(index)}
        />
      ),
    },
    {
      width: '15%',
      render: (row, location, index) => (
        <Select
          name={`menuPdfFiles.${index}.menuCategory`}
          options={values(MENU_CATEGORIES)}
        />
      ),
    },
    {
      dataIndex: 'fileText',
      align: 'left',
      width: '20%',
      render: (fileText, record, index) => (
        <FormText
          name={`menuPdfFiles[${index}].fileText`}
          transform={value => `«${value}»`}
        />
      ),
    },
    {
      dataIndex: 'fileName',
      align: 'left',
      width: '20%',
      render: (fileName, record) => (
        <Button type="link" onClick={() => onMenuPdfFileNameClick(record)}>
          {fileName}
        </Button>
      ),
    },
    {
      width: '40%',
      render: (row, location, index) => (
        <>
          <HiddenInputs
            names={[
              `menuPdfFiles[${index}].id`,
              `menuPdfFiles[${index}].fileName`,
              `menuPdfFiles[${index}].fileBucketName`,
              `menuPdfFiles[${index}].fileKey`,
              `menuPdfFiles[${index}].isSelected`,
            ]}
          />
        </>
      ),
    },
  ]

  const renderRecrawlButton = () => (
    <Popconfirm
      title="Are you sure you want to recrawl the menu?"
      onConfirm={() => recrawlMenu(false)}
      placement="right"
      okText="Yes"
      cancelText="No"
    >
      <Button margin="no no no no" disabled={isRecrawlButtonDisabled}>
        Recrawl
        <Icon type="retweet" />
      </Button>
    </Popconfirm>
  )

  const recrawlButtonMenu = useMemo(
    () => (
      <Menu onClick={() => recrawlMenu(true)}>
        <Menu.Item key="1">Recrawl with fallback settings</Menu.Item>
      </Menu>
    ),
    [recrawlMenu],
  )

  return (
    <>
      {(isAssigningTask ||
        isUnassigningTask ||
        isMarkingTaskAsAwaiting ||
        isUnmarkingTaskAsAwaiting ||
        isReopeningTask ||
        isSavingTask ||
        isResolvingTask ||
        isResolvingTaskByRecrawlingMenu) && <Spinner size="large" />}

      <Breadcrumb currentTaskType={MENU_SCRAPING} items={breadcrumbItems} />

      <PageHeader sticky>
        <PageTitle>
          {pageTitle}
          <HistoryDrawer entityType={MENU_SCRAPING_TASK} entityId={task.id} />
        </PageTitle>

        <PageActions>
          <TaskActionSection
            task={watch('task')}
            permissions={MENU_SCRAPING_TASKS_PERMISSIONS}
            onAssignTask={onAssignTask}
            onUnassignTask={onUnassignTask}
            onSave={onSave}
            isSaveDisabled={false}
            // onDone={onDone}
            onMarkTaskAsAwaiting={onMarkTaskAsAwaiting}
            onUnmarkTaskAsAwaiting={onUnmarkTaskAsAwaiting}
            onReopenTask={onReopenTask}
            cancelUrl="/tasks/menu-scraping"
            canReopenTask={canReopenTask}
            menu={watch('menu')}
          />
        </PageActions>
      </PageHeader>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Collapse
            defaultActiveKey={[
              'menu-section',
              'crawling-service-information',
              'menu-specification',
              'task-section',
            ]}
          >
            <Collapse.Panel
              header="Menu"
              key="menu-section"
              style={has(errors, 'menu') ? { border: '1px solid red' } : {}}
            >
              <Row gutter={24}>
                <Col span={3}>
                  <FormInternalLink
                    transform={() => `/menus/${task.menu.id}`}
                    name="menu.id"
                    label="Menu ID"
                  />
                </Col>
                <Col span={4}>
                  <FormText name="menu.brand.name" label="Brand" />
                </Col>
                {!isEmpty(task.menu.locations) && (
                  <Col span={4}>
                    <LocationsList name="menu.locations" label="Location(s)" />
                  </Col>
                )}
                <Col span={5}>
                  <FormLink name="menu.url" label="Menu website" />
                </Col>
                <Col span={3}>
                  <FormText name="menu.status" label="Status" />
                </Col>
                <Col span={3}>
                  <FormDate name="menu.modifiedDate" label="Updated at" />
                </Col>
              </Row>
              <Row gutter={[24, 16]}>
                <Col span={3}>
                  <Select
                    style={{ width: '80%' }}
                    label="Format"
                    name="menu.format"
                    options={menuFormatOptions}
                    onSelectChange={onBrandMenuFormatChange}
                  />
                </Col>

                <VisibilityContainer
                  isHidden={watch('menu.format') !== HTML_NOT_SCRAPEABLE}
                >
                  <Col span={5}>
                    <Select
                      style={{ width: '100%' }}
                      name="menu.notScrapeableReason"
                      label="Not scrapeable reason"
                      options={notScrapableReasonOptions}
                    />
                  </Col>
                </VisibilityContainer>

                <Col span={8}>
                  <AntForm.Item label="Actions">
                    <ButtonGroup>
                      {renderRecrawlButton(false)}
                      <Dropdown
                        overlay={recrawlButtonMenu}
                        placement="bottomLeft"
                        disabled={isRecrawlButtonDisabled}
                      >
                        <Button margin="no small no no">
                          <Icon type="down" />
                        </Button>
                      </Dropdown>
                    </ButtonGroup>
                    <Button
                      margin="no small no no"
                      disabled={isRejectButtonDisabled}
                      onClick={() => setIsRejectMenuModalOpen(true)}
                    >
                      Reject
                      <Icon type="stop" />
                    </Button>
                  </AntForm.Item>
                </Col>
              </Row>

              <Row gutter={[24, 16]}>
                <VisibilityContainer
                  isHidden={watch('menu.format') !== HTML_NOT_SCRAPEABLE}
                >
                  <Col span={8}>
                    <TextArea
                      label="Not scrapeable comment"
                      name="menu.notScrapeableComment"
                    />
                  </Col>
                </VisibilityContainer>
              </Row>

              <Row gutter={[24, 16]}>
                {isScrapeable && (
                  <>
                    <Col span={3}>
                      <Upload
                        disabled={isAttachedToCluster}
                        label="Scraping instructions"
                        name="menu.scrapingInstructions"
                        accept=".json"
                      />
                    </Col>

                    <Col span={4}>
                      <AntForm.Item label="Scraping responses">
                        <HtmlMenuScrapingHistoryDrawer
                          menuId={task.menu.id}
                          buttonName="View"
                          buttonSize="default"
                        />
                      </AntForm.Item>
                    </Col>

                    <VisibilityContainer
                      isHidden={!task.menu.isAwaitingScraping}
                    >
                      <Col span={5}>
                        <Form.Item label="Scraping status">
                          <Badge status="processing" text="In progress" />
                        </Form.Item>
                      </Col>
                    </VisibilityContainer>
                  </>
                )}
              </Row>
              <Row gutter={[24, 16]}>
                <Col span={3}>
                  <FormInternalLink
                    name="menu.dishReviewTask.id"
                    label="Dish review task ID"
                    transform={id => `/tasks/dish-review/${id}`}
                  />
                </Col>
                <Col span={4}>
                  <FormDate
                    name="menu.crawlingRejectedDate"
                    label="Crawling rejected at"
                  />
                </Col>
                <Col span={4}>
                  <FormText
                    name="menu.crawlingRejectedBy.name"
                    label="Crawling rejected by"
                  />
                </Col>
                <VisibilityContainer
                  isHidden={task.menu.status !== AUTO_REJECTED}
                >
                  <Col span={14}>
                    <FormText
                      name="menu.crawlingRejectedReason"
                      label="Rejection reason"
                    />
                  </Col>
                </VisibilityContainer>
                <VisibilityContainer
                  isHidden={task.menu.status === AUTO_REJECTED}
                >
                  <Col span={12}>
                    <FormText
                      name="menu.crawlingRejectedComment"
                      label="Crawling rejected comment"
                      ellipsis={false}
                      style={{ whiteSpace: 'break-spaces' }}
                    />
                  </Col>
                </VisibilityContainer>
              </Row>
            </Collapse.Panel>
            {menuFormat === PDF && (
              <Collapse.Panel
                header="Crawling Service Information"
                key="crawling-service-information"
              >
                <Col span={3}>
                  <AntForm.Item label="Crawling response">
                    <Button
                      disabled={
                        isAttachedToCluster ||
                        !menu.menuCrawlingJob?.menuCrawlingResponse
                      }
                      onClick={() => {
                        fileDownload(
                          menu.menuCrawlingJob.menuCrawlingResponse.data,
                          `${menu.id}-crawling-response.json`,
                        )
                      }}
                    >
                      Download
                      <Icon type="download" />
                    </Button>
                  </AntForm.Item>
                </Col>
                <Col span={4}>
                  <FormText
                    name="menu.crawlingDuration"
                    label="Duration"
                    valuePostfix="s"
                  />
                </Col>
                <Col span={4}>
                  <FormText name="menu.crawlingStatus" label="Status" />
                </Col>
                <Col span={4}>
                  <FormText
                    name="menu.crawledUsingFallbackSettings"
                    label="Crawled using fallback settings"
                  />
                </Col>
              </Collapse.Panel>
            )}
            {menuFormat === PDF && (
              <Collapse.Panel
                header="Menu Specification"
                key="menu-specification"
              >
                {menuPdfFiles && menuPdfFiles.length > 0 ? (
                  <Table
                    columns={menuPdfFilesTableColumns}
                    dataSource={menuPdfFiles}
                    rowKey={record => record.id}
                    showHeader={false}
                    pagination={false}
                  />
                ) : (
                  <p>No menu pdf files.</p>
                )}
              </Collapse.Panel>
            )}
            <Collapse.Panel header="Task" key="task-section">
              <Row gutter={16}>
                <Col span={3}>
                  <AwaitingReasonField
                    valueFieldName="task.awaitingReason"
                    titleFieldName="task.awaitingReasonComment"
                    label="Awaiting reason"
                  />
                </Col>
                <Col span={3}>
                  <FormDate label="Updated at" name="task.modifiedDate" />
                </Col>
                <VisibilityContainer isHidden={taskStatus !== REOPENED}>
                  <>
                    <Col span={3}>
                      <FormText
                        label="Reopened by"
                        name="task.reopenedBy.name"
                      />
                    </Col>
                    <Col span={3}>
                      <FormDate label="Reopened at" name="task.reopenedDate" />
                    </Col>
                    <Col span={4}>
                      <FormText
                        label="Reopen reason"
                        name="task.reopenReason"
                      />
                    </Col>
                  </>
                </VisibilityContainer>
                <Col span={2}>
                  <FormText
                    name="task.isRerunFlow"
                    label="Rerun flow"
                    style={{
                      textAlign: task.isRerunFlow ? 'center' : '',
                      backgroundColor: task.isRerunFlow
                        ? THEME.colors.orange
                        : '',
                    }}
                  />
                </Col>
                <Col span={3}>
                  <AntForm.Item label="Miscellaneous task">
                    <MiscTaskVerificationThreeStateSwitch
                      task={watch('task')}
                      onVerifiedButtonClick={onVerifiedButtonClick}
                      onQCVerifiedButtonClick={onQCVerifiedButtonClick}
                      loading={
                        isMarkingMiscTaskAsVerified ||
                        isMarkingMiscTaskAsQCVerified
                      }
                      loggedInUser={loggedInUser}
                    />
                  </AntForm.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>

          <HiddenInputs
            names={[
              'task.id',
              'task.assignedTo.id',
              'task.assignedTo.name',
              'task.status',
              'task.awaitingReason',
              'task.awaitingReasonComment',
              'task.verifiedBy.id',
              'task.verifiedBy.name',
              'task.verifiedDate',
              'task.resolvedBy.id',
              'task.resolvedBy.name',
              'task.reopenedDate',
              'task.reopenedBy.id',
              'task.reopenedBy.name',
              'task.reopenReason',
              'menu.crawlingDuration',
              'task.miscTaskVerifiedBy.id',
              'task.miscTaskVerifiedBy.name',
              'task.miscTaskVerifiedDate',
              'task.miscTaskVerifiedComment',
              'task.miscTaskQCVerifiedBy.id',
              'task.miscTaskQCVerifiedBy.name',
              'task.miscTaskQCVerifiedDate',
              'task.miscTaskQCVerifiedComment',
            ]}
          />
          <HiddenCheckboxes
            names={['task.isAssigned', 'task.isAwaiting', 'task.isDone']}
          />
        </form>
      </FormProvider>

      <RejectPdfMenuCrawlingModal
        taskId={task.id}
        menu={menu}
        isModalOpen={isRejectMenuModalOpen}
        setIsModalOpen={setIsRejectMenuModalOpen}
      />

      <MarkMiscTaskAsVerifiedModal
        isModalOpen={isMarkMiscTaskAsVerifiedModalOpen}
        isQCVerified={isQCVerified}
        comment={
          isQCVerified ? miscTaskQCVerifiedComment : miscTaskVerifiedComment
        }
        onSubmit={handleMarkMiscTaskAsVerified}
        closeModal={() => setIsMarkMiscTaskAsVerifiedModalOpen(false)}
      />
    </>
  )
}

PdfMenuScrapingTaskForm.propTypes = {
  task: PropTypes.object.isRequired,
  isSavingTask: PropTypes.bool.isRequired,
  isResolvingTask: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default PdfMenuScrapingTaskForm
