import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import { isNil } from 'lodash-es'
import PropTypes from 'prop-types'

import { Button } from '../../../../core/components/styled'
import { THEME } from '../../../../core/constants'

import { formatDate } from '../../../../common/services/formatter'
import { areSameValue } from '../../../../common/utils'

const { Group: ButtonGroup } = Button

const MiscTaskVerificationThreeStateSwitch = ({
  task,
  onVerifiedButtonClick,
  onQCVerifiedButtonClick,
  loading,
  loggedInUser,
}) => {
  const isVerified = useMemo(() => !isNil(task.miscTaskVerifiedBy?.name), [
    task.miscTaskVerifiedBy,
  ])

  const isQCVerified = useMemo(() => !isNil(task.miscTaskQCVerifiedBy?.name), [
    task.miscTaskQCVerifiedBy,
  ])

  const hasPermissionToUpdateVerifiedComment = useMemo(
    () =>
      isVerified && areSameValue(task.miscTaskVerifiedBy?.id, loggedInUser.id),
    [isVerified, loggedInUser.id, task.miscTaskVerifiedBy],
  )

  const hasPermissionToUpdateQCVerifiedComment = useMemo(
    () =>
      isQCVerified &&
      areSameValue(task.miscTaskQCVerifiedBy?.id, loggedInUser.id),
    [isQCVerified, loggedInUser.id, task.miscTaskQCVerifiedBy],
  )

  const isNotVerifiedButtonDisabled = true

  const isVerifiedButtonDisabled = useMemo(
    () => loading || (isVerified && !hasPermissionToUpdateVerifiedComment),
    [loading, isVerified, hasPermissionToUpdateVerifiedComment],
  )

  const isQCVerifiedButtonDisabled = useMemo(
    () =>
      loading ||
      !isVerified ||
      (isQCVerified && !hasPermissionToUpdateQCVerifiedComment),
    [loading, isVerified, isQCVerified, hasPermissionToUpdateQCVerifiedComment],
  )

  const buttonCommonStyle = useMemo(
    () => ({
      borderRadius: 0,
    }),
    [],
  )

  const buttonSelectedStyle = useMemo(
    () => ({
      backgroundColor: THEME.colors.greyBlue,
      color: THEME.colors.white,
    }),
    [],
  )

  const notVerifiedButtonStyle = useMemo(
    () => ({
      ...buttonCommonStyle,
      ...(!isVerified && buttonSelectedStyle),
    }),
    [buttonCommonStyle, buttonSelectedStyle, isVerified],
  )

  const verifiedButtonStyle = useMemo(
    () => ({
      ...buttonCommonStyle,
      ...(isVerified && !isQCVerified && buttonSelectedStyle),
    }),
    [buttonCommonStyle, buttonSelectedStyle, isVerified, isQCVerified],
  )

  const qcVerifiedButtonStyle = useMemo(
    () => ({
      ...buttonCommonStyle,
      ...(isQCVerified && buttonSelectedStyle),
    }),
    [buttonCommonStyle, buttonSelectedStyle, isQCVerified],
  )

  const verifiedInfo = isVerified ? (
    <span>
      Verified by: {task.miscTaskVerifiedBy.name}
      <br />
      Verified date: {formatDate(task.miscTaskVerifiedDate, false)}
      <br />
      Verified comment: {task.miscTaskVerifiedComment || ''}
    </span>
  ) : (
    ''
  )

  const qcVerifiedInfo = isQCVerified ? (
    <span>
      QC Verified by: {task.miscTaskQCVerifiedBy.name}
      <br />
      QC Verified date: {formatDate(task.miscTaskQCVerifiedDate, false)}
      <br />
      QC Verified comment: {task.miscTaskQCVerifiedComment || ''}
    </span>
  ) : (
    ''
  )

  return (
    <ButtonGroup margin="no small no no" style={{ display: 'flex' }}>
      <Button
        type={!isVerified ? 'primary' : 'secondary'}
        style={notVerifiedButtonStyle}
        disabled={isNotVerifiedButtonDisabled}
      >
        Not Verified
      </Button>

      <Tooltip
        title={verifiedInfo}
        trigger="hover"
        placement="topRight"
        overlayClassName="msic-task-verification-status-tooltip"
      >
        <Button
          style={verifiedButtonStyle}
          disabled={isVerifiedButtonDisabled}
          onClick={onVerifiedButtonClick}
        >
          Verified
        </Button>
      </Tooltip>
      <Tooltip
        title={qcVerifiedInfo}
        trigger="hover"
        placement="topRight"
        overlayClassName="msic-task-verification-status-tooltip"
      >
        <Button
          style={qcVerifiedButtonStyle}
          disabled={isQCVerifiedButtonDisabled}
          onClick={onQCVerifiedButtonClick}
        >
          QC Verified
        </Button>
      </Tooltip>
    </ButtonGroup>
  )
}

MiscTaskVerificationThreeStateSwitch.propTypes = {
  task: PropTypes.object.isRequired,
  onVerifiedButtonClick: PropTypes.func.isRequired,
  onQCVerifiedButtonClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loggedInUser: PropTypes.object.isRequired,
}

export default MiscTaskVerificationThreeStateSwitch
