import React, { useCallback, useContext, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import {
  Col,
  Collapse,
  Dropdown,
  Form as AntForm,
  Icon,
  List,
  Menu,
  Popconfirm,
  Row,
  Typography,
} from 'antd'
import fileDownload from 'js-file-download'
import { isEmpty, isNull, join, map } from 'lodash-es'
import PropTypes from 'prop-types'

import {
  Breadcrumb,
  FormDate,
  FormInternalLink,
  FormLink,
  FormText,
  HiddenCheckboxes,
  HiddenInputs,
} from '../../../core/components'
import { Button } from '../../../core/components/styled'
import { THEME } from '../../../core/constants'
import { getQueryParams } from '../../../core/utils/services/queryParams'

import {
  HistoryDrawer,
  HtmlMenuScrapingHistoryDrawer,
  ScrapingInstructionsVersionsDrawer,
  ScrapingRejectedCommentHistoryDrawer,
  Spinner,
  VisibilityContainer,
} from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import {
  ADMIN,
  DEFAULT_PAGE,
  DISH_REVIEW_TASK,
  DISH_REVIEW_TASKS_PERMISSIONS,
  EMPTY_VALUE,
  HTML_AUTOMATIC,
  MENU_SCRAPING_TYPES,
  TEAM_LEADER,
} from '../../../common/constants'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'
import { useIsLoggedInUser, useUserHasRoles } from '../../../common/hooks'
import { getCachedFilterForPage } from '../../../common/services'
import { formatSecondsToTime } from '../../../common/services/formatter'

import { AuthContext } from '../../../account/contexts'
import { HtmlMenuScrapingDebugModal } from '../../../menus/components/pages'
import {
  HtmlMenuTitlesAndDishesTables,
  ScrapingAnalysis,
} from '../../../menus/components/pages/sections/html-menus'
import {
  AUTO_REJECTED,
  HTML,
  MENU_SCRAPING_STATUSES,
  MENU_STATUSES,
  NOT_SCRAPEABLE,
} from '../../../menus/constants'
import { SEND_MENU_FOR_TESTING } from '../../../menus/graphql/mutations'
import { MENU_SCRAPING_REPORT_URL } from '../../../menus/graphql/queries'
import {
  isHtmlMenuScrapedInDebugMode,
  isMenuScrapedSuccessfully,
} from '../../../menus/helpers'
import {
  DISH_REVIEW,
  MANUAL_CHANGE,
  REOPENED,
  RESOLVED,
  TASK_AWAITING_REASONS,
  TASK_REOPEN_REASONS,
} from '../../constants'
import {
  ASSIGN_DISH_REVIEW_TASK,
  MARK_DISH_REVIEW_TASK_AS_AWAITING,
  MARK_DISH_REVIEW_TASK_MISC_AS_QC_VERIFIED,
  MARK_DISH_REVIEW_TASK_MISC_AS_VERIFIED,
  REOPEN_DISH_REVIEW_TASK,
  RESOLVE_DISH_REVIEW_TASK_BY_RESCRAPE_MENU,
  UNASSIGN_DISH_REVIEW_TASK,
  UNMARK_DISH_REVIEW_TASK_AS_AWAITING,
} from '../../graphql/mutations'
import { getBreadcrumbItemsForDishReviewTaskPage } from '../../helpers'
import {
  AwaitingReasonField,
  LocationsList,
  MarkMiscTaskAsVerifiedModal,
  MenuAvailabilityCommentModal,
  MenuScrapingIssueCommentModal,
  MiscTaskVerificationThreeStateSwitch,
  RejectHtmlMenuScrapingModal,
  TaskActionSection,
} from '../pages/sections'

import { dishReviewTaskFormInitialValues } from './initial-values'

const { Group: ButtonGroup } = Button

const HtmlDishReviewTaskForm = ({
  task,
  isSavingTask,
  isResolvingTask,
  onSubmit,
}) => {
  const { user: loggedInUser } = useContext(AuthContext)

  const [
    isScrapingIssueCommentModalOpen,
    setIsScrapingIssueCommentModalOpen,
  ] = useState(false)

  const [isScrapingIssueCommentSet, setIsScrapingIssueCommentSet] = useState(
    !!task.menu.scrapingIssueComment,
  )

  const [isRejectMenuModalOpen, setIsRejectMenuModalOpen] = useState(false)

  const [
    isMenuAvailabilityCommentModalOpen,
    setIsMenuAvailabilityCommentModalOpen,
  ] = useState(false)

  const [isScrapingDebugModalOpen, setIsScrapingDebugModalOpen] = useState(
    false,
  )

  const [selectedItemHash, setSelectedItemHash] = useState(null)

  const [isQCVerified, setIsQCVerified] = useState(false)

  const [
    isMarkMiscTaskAsVerifiedModalOpen,
    setIsMarkMiscTaskAsVerifiedModalOpen,
  ] = useState(false)

  const history = useHistory()
  const { search } = useLocation()
  const { page = DEFAULT_PAGE, pageSize = 40 } = getQueryParams(search)

  const formInitialValues = useMemo(
    () => dishReviewTaskFormInitialValues(task),
    [task],
  )

  const formMethods = useForm({
    defaultValues: formInitialValues,
  })

  const { handleSubmit, getValues, setValue, control, watch } = formMethods

  const { menu } = task
  const isLoggedInUser = useIsLoggedInUser(watch('task.assignedTo'))
  const taskStatus = watch('task.status')
  const miscTaskVerifiedComment = watch('task.miscTaskVerifiedComment')
  const miscTaskQCVerifiedComment = watch('task.miscTaskQCVerifiedComment')

  const isTaskAssignedToLoggedInUser = watch('task.isAssigned')
    ? isLoggedInUser
    : false

  const isAdminOrTeamLeader = useUserHasRoles([ADMIN, TEAM_LEADER])

  const isAllowedToRescrape =
    (isTaskAssignedToLoggedInUser || isAdminOrTeamLeader) &&
    menu.status !== NOT_SCRAPEABLE

  const isAllowedToReject = isTaskAssignedToLoggedInUser || isAdminOrTeamLeader

  const pageTitle = useMemo(
    () => (menu.format === HTML ? 'HTML Dish Review Task' : 'Dish Review Task'),
    [menu.format],
  )

  const isRejectButtonDisabled = useMemo(
    () =>
      taskStatus === RESOLVED ||
      menu.status === AUTO_REJECTED ||
      menu.scrapingType === HTML_AUTOMATIC ||
      !isAllowedToReject,
    [menu.status, menu.scrapingType, taskStatus, isAllowedToReject],
  )

  const isRescrapingHistoryButtonDisabled = useMemo(
    () => menu.scrapingType === HTML_AUTOMATIC,
    [menu.scrapingType],
  )

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_DISH_REVIEW_TASK,
    {
      onCompleted({ assignDishReviewTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: assignDishReviewTask.modifiedDate,
          isAssigned: true,
          assignedTo: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Dish review task assigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Dish review task assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_DISH_REVIEW_TASK,
    {
      onCompleted({ unassignDishReviewTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: unassignDishReviewTask.modifiedDate,
          isAssigned: false,
          assignedTo: {
            id: '',
            name: '',
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Dish review task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Dish review task unassign failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsAwaiting,
    { loading: isMarkingTaskAsAwaiting },
  ] = useMutation(MARK_DISH_REVIEW_TASK_AS_AWAITING, {
    onCompleted({ markDishReviewTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        isAwaiting: true,
        modifiedDate: markDishReviewTaskAsAwaiting.modifiedDate,
        awaitingReason:
          TASK_AWAITING_REASONS[markDishReviewTaskAsAwaiting.awaitingReason]
            .name,
        awaitingReasonComment:
          markDishReviewTaskAsAwaiting.awaitingReasonComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Dish review task marked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Dish review task mark as awaiting failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsAwaiting,
    { loading: isUnmarkingTaskAsAwaiting },
  ] = useMutation(UNMARK_DISH_REVIEW_TASK_AS_AWAITING, {
    onCompleted({ unmarkDishReviewTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: unmarkDishReviewTaskAsAwaiting.modifiedDate,
        isAwaiting: false,
        awaitingReason: '',
        awaitingReasonComment: '',
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Dish review task unmarked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Dish review task unmark as awaiting failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsVerified,
    { loading: isMarkingMiscTaskAsVerified },
  ] = useMutation(MARK_DISH_REVIEW_TASK_MISC_AS_VERIFIED, {
    onCompleted({
      markDishReviewTaskMiscAsVerified: {
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskVerifiedBy: {
          id: loggedInUser.id,
          name: loggedInUser.name,
        },
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as verified failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsQCVerified,
    { loading: isMarkingMiscTaskAsQCVerified },
  ] = useMutation(MARK_DISH_REVIEW_TASK_MISC_AS_QC_VERIFIED, {
    onCompleted({
      markDishReviewTaskMiscAsQCVerified: {
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskQCVerifiedBy: {
          id: loggedInUser.id,
          name: loggedInUser.name,
        },
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as QC verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as QC verified failed.',
        description: message,
      })
    },
  })

  const [reopenTask, { loading: isReopeningTask }] = useMutation(
    REOPEN_DISH_REVIEW_TASK,
    {
      onCompleted({ reopenDishReviewTask: { modifiedDate, reopenedDate } }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate,
          reopenedBy: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
          reopenedDate,
          reopenReason: TASK_REOPEN_REASONS[MANUAL_CHANGE].name,
          status: REOPENED,
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Dish review task reopened.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Dish review task reopen failed.',
          description: message,
        })
      },
    },
  )

  const [
    resolveDishReviewTaskByRescrapeMenu,
    { loading: isResolvingDishReviewTaskByRescrapingMenu },
  ] = useMutation(RESOLVE_DISH_REVIEW_TASK_BY_RESCRAPE_MENU, {
    onCompleted() {
      showSuccessNotification({
        message: 'Dish review task resolved.',
        description: 'Dish review was task resolved by rescraping menu.',
      })
      history.push(getCachedFilterForPage('/tasks/dish-review'))
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Dish review task unassign failed.',
        description: message,
      })
    },
  })

  const [
    sendMenuForTesting,
    { loading: isSendingMenuForTesting },
  ] = useMutation(SEND_MENU_FOR_TESTING, {
    onCompleted() {
      showSuccessNotification({
        message: 'Menu sent for testing.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Sent menu for testing failed.',
        description: message,
      })
    },
  })

  const [
    getScrapingReportUrl,
    { data: { menuScrapingReportUrl } = {}, loading },
  ] = useLazyQuery(MENU_SCRAPING_REPORT_URL, {
    variables: { id: menu.id },
    onCompleted() {
      window.open(menuScrapingReportUrl, '_blank')
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Download scraping report failed.',
        description: message,
      })
    },
    fetchPolicy: 'no-cache',
  })

  const onAssignTask = useCallback(
    () =>
      assignTask({
        variables: { id: task.id, toUserId: loggedInUser.id },
      }),
    [assignTask, task, loggedInUser],
  )

  const onUnassignTask = useCallback(
    () => unassignTask({ variables: { id: task.id } }),
    [unassignTask, task],
  )

  const onSave = useCallback(async () => {
    setValue('task.isDone', false)
    handleSubmit(onSubmit)()
  }, [setValue, handleSubmit, onSubmit])

  const onDone = useCallback(async () => {
    setValue('task.isDone', true)
    handleSubmit(formValues => onSubmit(formValues))()
  }, [setValue, handleSubmit, onSubmit])

  const onMarkTaskAsAwaiting = useCallback(
    awaitingReasonData =>
      markTaskAsAwaiting({
        variables: { id: task.id, ...awaitingReasonData },
      }),
    [markTaskAsAwaiting, task],
  )

  const onUnmarkTaskAsAwaiting = useCallback(() => {
    unmarkTaskAsAwaiting({ variables: { id: task.id } })
  }, [unmarkTaskAsAwaiting, task])

  const onReopenTask = useCallback(
    () => reopenTask({ variables: { id: task.id } }),
    [reopenTask, task],
  )

  const handleDownloadButtonClick = useCallback(() => getScrapingReportUrl(), [
    getScrapingReportUrl,
  ])

  const handleOpenScrapingDebugModal = useCallback(hash => {
    setSelectedItemHash(hash)
    setIsScrapingDebugModalOpen(true)
  }, [])

  const handleCloseScrapingDebugModal = useCallback(() => {
    setSelectedItemHash(null)
    setIsScrapingDebugModalOpen(false)
  }, [])

  const { fields: menuTitles } = useFieldArray({
    control,
    name: 'menuTitles',
  })

  const { fields: dishes } = useFieldArray({
    control,
    name: 'dishes',
  })

  const resolveTaskByRescrapeMenuInDefaultMode = useCallback(
    () =>
      resolveDishReviewTaskByRescrapeMenu({
        variables: {
          id: task.id,
          isDebugModeScraping: false,
        },
      }),
    [resolveDishReviewTaskByRescrapeMenu, task.id],
  )

  const resolveTaskByRescrapeMenuInDebugMode = useCallback(
    () =>
      resolveDishReviewTaskByRescrapeMenu({
        variables: {
          id: task.id,
          isDebugModeScraping: true,
        },
      }),
    [resolveDishReviewTaskByRescrapeMenu, task.id],
  )

  const rescrapeButtonMenu = useMemo(
    () => (
      <Menu onClick={resolveTaskByRescrapeMenuInDebugMode}>
        <Menu.Item key="1">Rescrape in debug mode</Menu.Item>
      </Menu>
    ),
    [resolveTaskByRescrapeMenuInDebugMode],
  )

  const scrapedDateDetails = useMemo(
    () => (task.menu.isScrapedAutomatically ? 'automatically' : ''),
    [task.menu],
  )
  const menuAnnotations = useMemo(
    () => join(map(menu.scrapingSummary?.menuAnnotations, 'fullMenu'), ', '),
    [menu],
  )

  const breadcrumbItems = useMemo(
    () => getBreadcrumbItemsForDishReviewTaskPage(task),
    [task],
  )

  const onCommentChanged = useCallback(
    (comment, status) => {
      setIsScrapingIssueCommentSet(!!comment)
      setValue('menu.status', MENU_STATUSES[status].name)
    },
    [setIsScrapingIssueCommentSet, setValue],
  )

  const onVerifiedButtonClick = () => {
    setIsQCVerified(false)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const onQCVerifiedButtonClick = () => {
    setIsQCVerified(true)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const handleMarkMiscTaskAsVerified = useCallback(
    ({ comment }) => {
      const data = { variables: { id: task.id, comment } }
      setIsMarkMiscTaskAsVerifiedModalOpen(false)

      return isQCVerified
        ? markMiscTaskAsQCVerified(data)
        : markMiscTaskAsVerified(data)
    },
    [isQCVerified, markMiscTaskAsVerified, markMiscTaskAsQCVerified, task],
  )

  const scrapingIssueButtonColor = useMemo(
    () => (isScrapingIssueCommentSet ? THEME.colors.red : THEME.colors.grey),
    [isScrapingIssueCommentSet],
  )

  const isAttachedToCluster = useMemo(() => !!menu.menuScrapingClusterId, [
    menu,
  ])

  const renderRescrapeButton = rightMargin => (
    <Button
      margin={rightMargin ? 'no small no no' : 'no no no no'}
      disabled={!isAllowedToRescrape || isAttachedToCluster}
      onClick={resolveTaskByRescrapeMenuInDefaultMode}
    >
      Rescrape
      <Icon type="retweet" />
    </Button>
  )

  return (
    <>
      {(isAssigningTask ||
        isUnassigningTask ||
        isSavingTask ||
        isResolvingTask ||
        isMarkingTaskAsAwaiting ||
        isUnmarkingTaskAsAwaiting ||
        isReopeningTask ||
        isResolvingDishReviewTaskByRescrapingMenu) && <Spinner size="large" />}

      <Breadcrumb currentTaskType={DISH_REVIEW} items={breadcrumbItems} />

      <PageHeader sticky>
        <PageTitle>
          {pageTitle}
          <HistoryDrawer entityType={DISH_REVIEW_TASK} entityId={task.id} />
        </PageTitle>
        <PageActions>
          <TaskActionSection
            task={watch('task')}
            permissions={DISH_REVIEW_TASKS_PERMISSIONS}
            onAssignTask={onAssignTask}
            onUnassignTask={onUnassignTask}
            onSave={onSave}
            onDone={onDone}
            onMarkTaskAsAwaiting={onMarkTaskAsAwaiting}
            onUnmarkTaskAsAwaiting={onUnmarkTaskAsAwaiting}
            onReopenTask={onReopenTask}
            cancelUrl="/tasks/dish-review"
            menu={watch('menu')}
          />
        </PageActions>
      </PageHeader>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Collapse
            defaultActiveKey={[
              'menu-section',
              'task-section',
              'scraping-summary-section',
            ]}
          >
            <Collapse.Panel header="Menu" key="menu-section">
              <Row gutter={[16, 16]}>
                <Col span={2}>
                  <FormInternalLink
                    transform={() => `/menus/${task.menu.id}`}
                    name="menu.id"
                    label="ID"
                  />
                </Col>
                <Col span={4}>
                  <FormLink name="menu.url" label="URL" />
                </Col>
                <Col span={2}>
                  <FormText name="menu.dishCount" label="Dishes #" />
                </Col>
                <Col span={4}>
                  <FormText
                    copyable={{ text: task.menu.brand.name }}
                    name="menu.brand.name"
                    label="Brand"
                  />
                </Col>
                {!isEmpty(task.menu.locations) && (
                  <Col span={5}>
                    <LocationsList name="menu.locations" label="Location(s):" />
                  </Col>
                )}
                <Col span={2}>
                  <AntForm.Item label="Availability">
                    <Button
                      disabled={isNull(menu.availabilityComment)}
                      onClick={() =>
                        setIsMenuAvailabilityCommentModalOpen(true)
                      }
                    >
                      <Icon type="audit" />
                    </Button>
                  </AntForm.Item>
                </Col>
                <Col span={3}>
                  <FormText name="menu.status" label="Status" />
                </Col>
                <Col span={2}>
                  <AntForm.Item label="Scraping issue">
                    <Button
                      disabled={isAttachedToCluster}
                      onClick={() => setIsScrapingIssueCommentModalOpen(true)}
                      style={{
                        color: scrapingIssueButtonColor,
                        borderColor: scrapingIssueButtonColor,
                      }}
                    >
                      <Icon type="form" />
                    </Button>
                  </AntForm.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                {isAttachedToCluster && (
                  <Col span={3}>
                    <FormInternalLink
                      name="menu.menuScrapingClusterId"
                      label="Scraping cluster ID"
                      transform={id =>
                        `/menu-scraping-templates/clusters/${id}`
                      }
                    />
                  </Col>
                )}
                <Col span={10}>
                  <AntForm.Item label="JSON">
                    <Button
                      disabled={isAttachedToCluster}
                      onClick={() => {
                        fileDownload(
                          menu.scrapingInstructions,
                          `${menu.id}.json`,
                        )
                      }}
                    >
                      Download
                      <Icon type="download" />
                    </Button>

                    <ScrapingInstructionsVersionsDrawer
                      disabled={isAttachedToCluster}
                      menu={task.menu}
                      buttonSize="default"
                    />

                    <HtmlMenuScrapingHistoryDrawer
                      menuId={task.menu.id}
                      buttonName="Scraping history"
                      buttonSize="default"
                    />

                    {!task.menu.menuScrapingClusterId && (
                      <ScrapingRejectedCommentHistoryDrawer
                        taskId={task.id}
                        isButtonDisabled={isRescrapingHistoryButtonDisabled}
                      />
                    )}
                  </AntForm.Item>
                </Col>
                <VisibilityContainer
                  isHidden={task.menu.status !== AUTO_REJECTED}
                >
                  <Col span={3}>
                    <FormText
                      name="menu.scrapingRejectedReason"
                      label="Rejection reason"
                    />
                  </Col>
                </VisibilityContainer>
                <Col span={8}>
                  <AntForm.Item label="Actions">
                    {menu.scrapingType === HTML_AUTOMATIC ? (
                      renderRescrapeButton(true)
                    ) : (
                      <ButtonGroup>
                        {renderRescrapeButton(false)}
                        <Dropdown
                          overlay={rescrapeButtonMenu}
                          placement="bottomLeft"
                          disabled={!isAllowedToRescrape || isAttachedToCluster}
                        >
                          <Button margin="no small no no">
                            <Icon type="down" />
                          </Button>
                        </Dropdown>
                      </ButtonGroup>
                    )}
                    <Button
                      margin="no small no no"
                      disabled={isRejectButtonDisabled}
                      onClick={() => setIsRejectMenuModalOpen(true)}
                    >
                      Reject
                      <Icon type="stop" />
                    </Button>
                    <Popconfirm
                      title="Are you sure you want to create a test case?"
                      onConfirm={() => {
                        sendMenuForTesting({ variables: { id: menu.id } })
                      }}
                      placement="right"
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        margin="no small no no"
                        loading={isSendingMenuForTesting}
                        disabled={menu.status === NOT_SCRAPEABLE}
                      >
                        Create test case
                        <Icon type="menu-unfold" />
                      </Button>
                    </Popconfirm>
                  </AntForm.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Task" key="task-section">
              <Row gutter={16}>
                <Col span={2}>
                  <FormText
                    name="task.isAutoResolved"
                    label="Is auto resolved"
                  />
                </Col>
                <Col span={3}>
                  <AwaitingReasonField
                    valueFieldName="task.awaitingReason"
                    titleFieldName="task.awaitingReasonComment"
                    label="Awaiting reason"
                  />
                </Col>
                <Col span={3}>
                  <FormDate label="Updated at" name="task.modifiedDate" />
                </Col>
                <VisibilityContainer isHidden={taskStatus !== REOPENED}>
                  <>
                    <Col span={2}>
                      <FormText
                        label="Reopened by"
                        name="task.reopenedBy.name"
                      />
                    </Col>
                    <Col span={3}>
                      <FormDate label="Reopened at" name="task.reopenedDate" />
                    </Col>
                    <Col span={2}>
                      <FormText
                        label="Reopen reason"
                        name="task.reopenReason"
                      />
                    </Col>
                  </>
                </VisibilityContainer>
                <Col span={2}>
                  <FormText
                    name="task.isRerunFlow"
                    label="Rerun flow"
                    style={{
                      textAlign: task.isRerunFlow ? 'center' : '',
                      backgroundColor: task.isRerunFlow
                        ? THEME.colors.orange
                        : '',
                    }}
                  />
                </Col>
                <Col span={3}>
                  <AntForm.Item label="Miscellaneous task">
                    <MiscTaskVerificationThreeStateSwitch
                      task={watch('task')}
                      onVerifiedButtonClick={onVerifiedButtonClick}
                      onQCVerifiedButtonClick={onQCVerifiedButtonClick}
                      loading={
                        isMarkingMiscTaskAsVerified ||
                        isMarkingMiscTaskAsQCVerified
                      }
                      loggedInUser={loggedInUser}
                    />
                  </AntForm.Item>
                </Col>
              </Row>
            </Collapse.Panel>
            {task.menu.scrapingSummary && (
              <Collapse.Panel
                header="Scraping summary"
                key="scraping-summary-section"
              >
                <Row gutter={16}>
                  <Col span={2}>
                    <FormText name="menu.scrapedBy.name" label="Scraped by" />
                  </Col>
                  <Col span={3}>
                    <FormDate
                      name="menu.scrapedDate"
                      label="Scraped date"
                      dateDetails={scrapedDateDetails}
                    />
                  </Col>
                  <Col span={3}>
                    <AntForm.Item label="Menu scraping status">
                      {isMenuScrapedSuccessfully(menu.scrapingSummary.status)
                        ? MENU_SCRAPING_STATUSES[menu.scrapingSummary.status]
                            .name
                        : EMPTY_VALUE}
                    </AntForm.Item>
                  </Col>
                  <Col span={3}>
                    <AntForm.Item label="Menu scraping type">
                      {menu.scrapingType
                        ? MENU_SCRAPING_TYPES[menu.scrapingType].name
                        : EMPTY_VALUE}
                    </AntForm.Item>
                  </Col>
                  <Col span={2}>
                    <AntForm.Item label="Duration">
                      {formatSecondsToTime(menu.scrapingSummary.duration)}
                    </AntForm.Item>
                  </Col>
                  <Col span={6}>
                    <AntForm.Item label="Error code">
                      <Typography.Paragraph>
                        {(!isMenuScrapedSuccessfully(
                          menu.scrapingSummary.status,
                        ) &&
                          menu.scrapingSummary.status) ||
                          EMPTY_VALUE}
                      </Typography.Paragraph>
                    </AntForm.Item>
                  </Col>
                  {menu.scrapingSummary.scrapingReport?.fileKey && (
                    <Col span={3}>
                      <AntForm.Item label="Scraping report">
                        <Button
                          margin="no small no no"
                          loading={loading}
                          onClick={handleDownloadButtonClick}
                        >
                          Download
                          <Icon type="download" />
                        </Button>
                      </AntForm.Item>
                    </Col>
                  )}
                  <Col span={2}>
                    <AntForm.Item label="Scraping details">
                      <Button
                        margin="no small no medium"
                        icon="eye"
                        disabled={!isHtmlMenuScrapedInDebugMode(menu)}
                        onClick={() => handleOpenScrapingDebugModal(menu.id)}
                      />
                    </AntForm.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={11}>
                    {menu.scrapingSummary.analysisSlim ? (
                      <ScrapingAnalysis
                        analysis={menu.scrapingSummary.analysisSlim}
                      />
                    ) : (
                      <AntForm.Item label="Menu analysis">
                        <Typography.Paragraph
                          style={{
                            lineHeight: '1.2em',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {menu.scrapingSummary.analysis || EMPTY_VALUE}
                        </Typography.Paragraph>
                      </AntForm.Item>
                    )}
                  </Col>
                  <Col span={6}>
                    <AntForm.Item label="Menu annotations">
                      <Typography.Paragraph
                        copyable={{
                          text: menuAnnotations,
                        }}
                        style={{ lineHeight: '1.2em' }}
                      >
                        {menuAnnotations}
                      </Typography.Paragraph>
                    </AntForm.Item>
                  </Col>
                  <Col span={5}>
                    <AntForm.Item label="Scraping flags">
                      <Typography.Paragraph style={{ lineHeight: '1.2em' }}>
                        {join(map(menu.scrapingSummary.flags, 'text'), ', ')}
                      </Typography.Paragraph>
                    </AntForm.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={11}>
                    <AntForm.Item
                      label="Messages"
                      style={{ maxHeight: '230px', overflowY: 'scroll' }}
                    >
                      {isEmpty(menu.scrapingSummary.messages) ? (
                        EMPTY_VALUE
                      ) : (
                        <List
                          dataSource={menu.scrapingSummary.messages || []}
                          renderItem={message => (
                            <List.Item>{message.text}</List.Item>
                          )}
                        />
                      )}
                    </AntForm.Item>
                  </Col>
                  <Col span={13}>
                    <AntForm.Item
                      label="Exceptions"
                      style={{ maxHeight: '230px', overflowY: 'scroll' }}
                    >
                      {isEmpty(menu.scrapingSummary.exceptions) ? (
                        EMPTY_VALUE
                      ) : (
                        <List
                          dataSource={menu.scrapingSummary.exceptions || []}
                          renderItem={exception => (
                            <List.Item>{exception.text}</List.Item>
                          )}
                        />
                      )}
                    </AntForm.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            )}
          </Collapse>

          {!isEmpty(menuTitles) && (
            <HtmlMenuTitlesAndDishesTables
              menu={menu}
              menuTitles={menuTitles}
              dishes={dishes}
              isExpanded={false}
              page={page}
              pageSize={pageSize}
              onShowScrapingDetailsButtonClick={handleOpenScrapingDebugModal}
            />
          )}

          <HiddenInputs
            names={[
              'task.id',
              'task.assignedTo.id',
              'task.assignedTo.name',
              'task.status',
              'task.awaitingReason',
              'task.awaitingReasonComment',
              'task.reopenedDate',
              'task.reopenedBy.id',
              'task.reopenedBy.name',
              'task.reopenReason',
              'task.miscTaskVerifiedBy.id',
              'task.miscTaskVerifiedBy.name',
              'task.miscTaskVerifiedDate',
              'task.miscTaskVerifiedComment',
              'task.miscTaskQCVerifiedBy.id',
              'task.miscTaskQCVerifiedBy.name',
              'task.miscTaskQCVerifiedDate',
              'task.miscTaskQCVerifiedComment',
            ]}
          />
          {map(task.menu.locations, (location, index) => (
            <HiddenInputs
              key={location.id}
              names={[`menu.locations.${index}`]}
            />
          ))}
          <HiddenCheckboxes
            names={['task.isAssigned', 'task.isDone', 'task.isAwaiting']}
          />
        </form>
      </FormProvider>

      <RejectHtmlMenuScrapingModal
        taskId={task.id}
        menuId={menu.id}
        isModalOpen={isRejectMenuModalOpen}
        setIsModalOpen={setIsRejectMenuModalOpen}
      />

      <MenuAvailabilityCommentModal
        comment={menu.availabilityComment}
        isModalOpen={isMenuAvailabilityCommentModalOpen}
        setIsModalOpen={setIsMenuAvailabilityCommentModalOpen}
      />

      <MenuScrapingIssueCommentModal
        menu={menu}
        isModalOpen={isScrapingIssueCommentModalOpen}
        setIsModalOpen={setIsScrapingIssueCommentModalOpen}
        onCommentChanged={onCommentChanged}
        disabled={!isTaskAssignedToLoggedInUser}
      />

      <HtmlMenuScrapingDebugModal
        menu={menu}
        itemHash={selectedItemHash}
        isModalOpen={isScrapingDebugModalOpen}
        closeModal={handleCloseScrapingDebugModal}
      />

      <MarkMiscTaskAsVerifiedModal
        isModalOpen={isMarkMiscTaskAsVerifiedModalOpen}
        isQCVerified={isQCVerified}
        comment={
          isQCVerified ? miscTaskQCVerifiedComment : miscTaskVerifiedComment
        }
        onSubmit={handleMarkMiscTaskAsVerified}
        closeModal={() => setIsMarkMiscTaskAsVerifiedModalOpen(false)}
      />
    </>
  )
}

HtmlDishReviewTaskForm.propTypes = {
  task: PropTypes.object.isRequired,
  isSavingTask: PropTypes.bool.isRequired,
  isResolvingTask: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default HtmlDishReviewTaskForm
