import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import {
  Col,
  Collapse,
  Form,
  Modal,
  Pagination,
  Row,
  Table,
  Typography,
} from 'antd'
import {
  differenceBy,
  drop,
  get,
  has,
  isNil,
  map,
  mergeWith,
  size,
  slice,
} from 'lodash-es'
import PropTypes from 'prop-types'

import {
  Breadcrumb,
  Checkbox,
  FormDate,
  FormInternalLink,
  FormText,
  HiddenCheckboxes,
  HiddenInputs,
  Input,
} from '../../../core/components'
import { Button } from '../../../core/components/styled'
import { createGoogleCoordinates } from '../../../core/utils/services'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import {
  GoogleSearch,
  HistoryDrawer,
  OpenLink,
  Spinner,
  VisibilityContainer,
} from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import {
  BRAND_DEFINITION_TASK,
  BRAND_DEFINITION_TASKS_PERMISSIONS,
  DEFAULT_PAGE_SIZE,
} from '../../../common/constants'
import { PAGE_SIZE_OPTIONS } from '../../../common/constants/pagination'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'

import { AuthContext } from '../../../account/contexts'
import { LocationGoogleMatchRank } from '../../../brands/components/pages/sections'
import {
  BUSINESS_STATUSES,
  MANUAL_GOOGLE_MATCH,
  NO_GOOGLE_MATCH,
} from '../../../brands/constants'
import {
  BRAND_DEFINITION,
  MANUAL_CHANGE,
  REOPENED,
  TASK_AWAITING_REASONS,
  TASK_REOPEN_REASONS,
} from '../../constants'
import {
  ASSIGN_BRAND_DEFINITION_TASK,
  MARK_BRAND_DEFINITION_TASK_AS_AWAITING,
  MARK_BRAND_DEFINITION_TASK_AS_QUALITY_CHECKED,
  MARK_BRAND_DEFINITION_TASK_MISC_AS_QC_VERIFIED,
  MARK_BRAND_DEFINITION_TASK_MISC_AS_VERIFIED,
  REOPEN_BRAND_DEFINITION_TASK,
  UNASSIGN_BRAND_DEFINITION_TASK,
  UNMARK_BRAND_DEFINITION_TASK_AS_AWAITING,
  UNMARK_BRAND_DEFINITION_TASK_AS_QUALITY_CHECKED,
} from '../../graphql/mutations'
import { getBreadcrumbItemsForBrandDefinitionTaskPage } from '../../helpers'
import { mergeFormValues } from '../../utils'
import { BrandDefinitionMap } from '../maps'
import {
  AwaitingReasonField,
  DuplicateLocationSelect,
  LocationAddressDrawer,
  MarkMiscTaskAsVerifiedModal,
  MiscTaskVerificationThreeStateSwitch,
  QualityCheckedSwitch,
  TaskActionSection,
} from '../pages/sections'

import { brandDefinitionTaskState } from './global-states'
import { brandDefinitionTaskSchema } from './schemas'
import { brandDefinitionTaskStateValidator } from './validators'

const getFormState = (taskState, page, pageSize = DEFAULT_PAGE_SIZE) => ({
  ...taskState,
  locations: slice(
    taskState.locations,
    (page - 1) * pageSize,
    (page - 1) * pageSize + pageSize,
  ),
})

const BrandDefinitionTaskForm = ({
  task,
  isSavingTask,
  isResolvingTask,
  isRefetchingTask,
  onSubmit,
}) => {
  const { user: loggedInUser } = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(1)

  const history = useHistory()
  const { pathname, search } = useLocation()
  const { pageSize: currentPageSize = DEFAULT_PAGE_SIZE } = getQueryParams(
    search,
  )

  const setCurrentPageSize = useCallback(
    pageSize => history.push(createUrl(pathname, search, { pageSize })),
    [history, search, pathname],
  )

  const taskStateCurrentValues = useMemo(() => brandDefinitionTaskState(task), [
    task,
  ])

  const [cachedBrandWebsiteValue, setCachedBrandWebsiteValue] = useState(
    task.brand.website,
  )

  const [taskState, setTaskState] = useState(taskStateCurrentValues)

  const [disabledLocationsForMap, setDisabledLocationsForMap] = useState(
    drop(taskState.locations, DEFAULT_PAGE_SIZE),
  )

  const [fsaRectangle, setFsaRectangle] = useState()
  const mapRef = useRef(null)

  const [isQCVerified, setIsQCVerified] = useState(false)

  const [
    isMarkMiscTaskAsVerifiedModalOpen,
    setIsMarkMiscTaskAsVerifiedModalOpen,
  ] = useState(false)

  const formMethods = useForm({
    defaultValues: getFormState(taskState, currentPage, currentPageSize),
    resolver: yupResolver(brandDefinitionTaskSchema),
  })

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    control,
    trigger,
    errors,
    formState: { isDirty, isSubmitted },
  } = formMethods

  useEffect(() => {
    const formValues = getFormState(
      taskStateCurrentValues,
      1,
      DEFAULT_PAGE_SIZE,
    )
    setTaskState(taskStateCurrentValues)

    setDisabledLocationsForMap(
      drop(taskStateCurrentValues.locations, DEFAULT_PAGE_SIZE),
    )

    reset(formValues)
  }, [taskStateCurrentValues, reset])

  const { fields: locations } = useFieldArray({
    control,
    name: 'locations',
  })

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_BRAND_DEFINITION_TASK,
    {
      onCompleted({ assignBrandDefinitionTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: assignBrandDefinitionTask.modifiedDate,
          isAssigned: true,
          assignedTo: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Brand definition task assigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Brand definition assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_BRAND_DEFINITION_TASK,
    {
      onCompleted({ unassignBrandDefinitionTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: unassignBrandDefinitionTask.modifiedDate,
          isAssigned: false,
          assignedTo: {
            id: '',
            name: '',
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Brand definition task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Brand definition task unassign failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsAwaiting,
    { loading: isMarkingTaskAsAwaiting },
  ] = useMutation(MARK_BRAND_DEFINITION_TASK_AS_AWAITING, {
    onCompleted({ markBrandDefinitionTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: markBrandDefinitionTaskAsAwaiting.modifiedDate,
        isAwaiting: true,
        awaitingReason:
          TASK_AWAITING_REASONS[
            markBrandDefinitionTaskAsAwaiting.awaitingReason
          ].name,
        awaitingReasonComment:
          markBrandDefinitionTaskAsAwaiting.awaitingReasonComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Brand definition task marked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Brand definition task mark as awaiting failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsAwaiting,
    { loading: isUnmarkingTaskAsAwaiting },
  ] = useMutation(UNMARK_BRAND_DEFINITION_TASK_AS_AWAITING, {
    onCompleted({ unmarkBrandDefinitionTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: unmarkBrandDefinitionTaskAsAwaiting.modifiedDate,
        isAwaiting: false,
        awaitingReason: '',
        awaitingReasonComment: '',
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Brand definition task unmarked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Brand definition task unmark as awaiting failed.',
        description: message,
      })
    },
  })

  const [reopenTask, { loading: isReopeningTask }] = useMutation(
    REOPEN_BRAND_DEFINITION_TASK,
    {
      onCompleted({
        reopenBrandDefinitionTask: { modifiedDate, reopenedDate },
      }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          qualityCheckedBy: {
            name: null,
          },
          qualityCheckedDate: null,
          modifiedDate,
          reopenedBy: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
          reopenedDate,
          reopenReason: TASK_REOPEN_REASONS[MANUAL_CHANGE].name,
          status: REOPENED,
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Brand definition task reopened.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Brand definition task reopen failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsQualityChecked,
    { loading: isMarkingTaskAsQualityChecked },
  ] = useMutation(MARK_BRAND_DEFINITION_TASK_AS_QUALITY_CHECKED, {
    onCompleted() {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        qualityCheckedBy: {
          name: loggedInUser.name,
        },
        qualityCheckedDate: new Date().toISOString(),
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Brand definition task marked as quality checked.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Brand definition task mark as quality checked failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsQualityChecked,
    { loading: isUnmarkingTaskAsQualityChecked },
  ] = useMutation(UNMARK_BRAND_DEFINITION_TASK_AS_QUALITY_CHECKED, {
    onCompleted() {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        qualityCheckedBy: {
          name: null,
        },
        qualityCheckedDate: null,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Brand definition task unmarked as quality checked.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Brand definition task unmark as quality checked failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsVerified,
    { loading: isMarkingMiscTaskAsVerified },
  ] = useMutation(MARK_BRAND_DEFINITION_TASK_MISC_AS_VERIFIED, {
    onCompleted({
      markBrandDefinitionTaskMiscAsVerified: {
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskVerifiedBy: {
          id: loggedInUser.id,
          name: loggedInUser.name,
        },
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as verified failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsQCVerified,
    { loading: isMarkingMiscTaskAsQCVerified },
  ] = useMutation(MARK_BRAND_DEFINITION_TASK_MISC_AS_QC_VERIFIED, {
    onCompleted({
      markBrandDefinitionTaskMiscAsQCVerified: {
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskQCVerifiedBy: {
          id: loggedInUser.id,
          name: loggedInUser.name,
        },
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as QC verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as QC verified failed.',
        description: message,
      })
    },
  })

  const onAssignTask = useCallback(
    () =>
      assignTask({
        variables: { id: task.id, toUserId: loggedInUser.id },
      }),
    [assignTask, task, loggedInUser],
  )

  const onUnassignTask = useCallback(
    () => unassignTask({ variables: { id: task.id } }),
    [unassignTask, task],
  )

  const onSave = useCallback(async () => {
    setValue('task.isDone', false)
    setCurrentPage(1)
    handleSubmit(onSubmit)()
  }, [setValue, handleSubmit, onSubmit])

  const onDone = useCallback(async () => {
    setValue('task.isDone', true)
    handleSubmit(async formValues => {
      const updatedTaskState = mergeWith(taskState, formValues, mergeFormValues)
      const isValid = await brandDefinitionTaskStateValidator(updatedTaskState)

      if (isValid) {
        onSubmit(updatedTaskState)
      }
    })()
  }, [setValue, taskState, handleSubmit, onSubmit])

  const onMarkTaskAsAwaiting = useCallback(
    awaitingReasonData =>
      markTaskAsAwaiting({
        variables: { id: task.id, ...awaitingReasonData },
      }),
    [markTaskAsAwaiting, task],
  )

  const onUnmarkTaskAsAwaiting = useCallback(() => {
    unmarkTaskAsAwaiting({ variables: { id: task.id } })
  }, [unmarkTaskAsAwaiting, task])

  const onReopenTask = useCallback(
    () => reopenTask({ variables: { id: task.id } }),
    [reopenTask, task],
  )

  const onQualityCheckChanged = useCallback(
    isQualityChecked =>
      isQualityChecked
        ? markTaskAsQualityChecked({ variables: { id: task.id } })
        : unmarkTaskAsQualityChecked({ variables: { id: task.id } }),
    [markTaskAsQualityChecked, unmarkTaskAsQualityChecked, task],
  )

  const onVerifiedButtonClick = () => {
    setIsQCVerified(false)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const onQCVerifiedButtonClick = () => {
    setIsQCVerified(true)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const handleMarkMiscTaskAsVerified = useCallback(
    ({ comment }) => {
      const data = { variables: { id: task.id, comment } }
      setIsMarkMiscTaskAsVerifiedModalOpen(false)

      return isQCVerified
        ? markMiscTaskAsQCVerified(data)
        : markMiscTaskAsVerified(data)
    },
    [isQCVerified, markMiscTaskAsVerified, markMiscTaskAsQCVerified, task],
  )

  const onMapInitialized = useCallback(map => {
    mapRef.current = map
  }, [])

  const centerMapToFsaLocation = useCallback(
    fsaLocation => {
      const {
        coordinates,
        geocodeNorthEastCoordinates,
        geocodeSouthWestCoordinates,
      } = fsaLocation

      if (fsaRectangle) {
        fsaRectangle.setMap(null)
      }

      if (!isNil(coordinates?.latitude) && !isNil(coordinates?.longitude)) {
        const coordinates = createGoogleCoordinates(
          fsaLocation.coordinates.latitude,
          fsaLocation.coordinates.longitude,
        )

        mapRef.current.map.panTo(coordinates)
        mapRef.current.map.setZoom(16)
      }

      if (
        (isNil(coordinates?.latitude) || isNil(coordinates?.longitude)) &&
        !isNil(geocodeNorthEastCoordinates?.latitude) &&
        !isNil(geocodeNorthEastCoordinates?.longitude) &&
        !isNil(geocodeSouthWestCoordinates?.longitude) &&
        !isNil(geocodeSouthWestCoordinates?.longitude)
      ) {
        const bounds = new mapRef.current.googleMaps.LatLngBounds(
          new mapRef.current.googleMaps.LatLng(
            Number(geocodeSouthWestCoordinates.latitude),
            Number(geocodeSouthWestCoordinates.longitude),
          ),
          new mapRef.current.googleMaps.LatLng(
            Number(geocodeNorthEastCoordinates.latitude),
            Number(geocodeNorthEastCoordinates.longitude),
          ),
        )

        const rectangle = new mapRef.current.googleMaps.Rectangle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          map,
          bounds,
        })

        mapRef.current.map.fitBounds(bounds)
        rectangle.setMap(mapRef.current.map)
        setFsaRectangle(rectangle)
      }
    },
    [fsaRectangle],
  )

  const centerMapToGooglePlaceLocation = useCallback(location => {
    const coordinates = createGoogleCoordinates(
      location.coordinates.latitude,
      location.coordinates.longitude,
    )

    mapRef.current.map.panTo(coordinates)
    mapRef.current.map.setZoom(16)
  }, [])

  const attachGooglePlaceToLocation = useCallback(
    (locationId, googleLocation) => {
      const formValues = getValues()
      const updatedLocations = map(formValues.locations, location =>
        location.id === locationId
          ? {
              ...location,
              name: googleLocation.name,
              website: googleLocation.website,
              phone: googleLocation.phone,
              businessStatus: googleLocation.businessStatus,
              googleLocationId: googleLocation.id,
              isHasNoGoogleMatchConfirmed: false,
              googleMatchRank: MANUAL_GOOGLE_MATCH,
              googleLocation,
            }
          : location,
      )

      reset({ ...formValues, locations: updatedLocations }, { keepDirty: true })
    },
    [getValues, reset],
  )

  const detachGooglePlaceFromLocation = useCallback(
    locationId => {
      const formValues = getValues()
      const updatedLocations = map(formValues.locations, location =>
        location.id === locationId
          ? {
              ...location,
              website: null,
              phone: null,
              businessStatus: null,
              googleLocationId: null,
              googleLocation: null,
              googleMatchRank: NO_GOOGLE_MATCH,
            }
          : location,
      )

      reset({ ...formValues, locations: updatedLocations })
    },
    [getValues, reset],
  )

  const tableColumns = [
    {
      title: '#',
      dataIndex: 'position',
      width: '75px',
      render: (position, location, index) => (
        <FormText name={`locations.${index}.position`} />
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: '80px',
      render: (id, location, index) => (
        <FormInternalLink
          name={`locations.${index}.id`}
          transform={id => `/brands/locations/${id}`}
        />
      ),
    },
    {
      title: 'Location',
      dataIndex: 'name',
      width: '200px',
      render: (name, location, index) =>
        watch('brand.useBrandNameAsLocationName') ? (
          watch('brand.name')
        ) : (
          <FormText name={`locations.${index}.name`} />
        ),
    },
    {
      title: 'H&S name',
      width: '250px',
      render: (row, location, index) => {
        const fsaLocation = get(
          getValues(),
          `locations.${index}.fsaLocation`,
          location.fsaLocation,
        )

        const { message: errorMessage } = get(
          errors,
          `locations.${index}.fsaLocationId`,
          {},
        )

        return (
          <>
            <Form.Item
              style={{ margin: '0px' }}
              validateStatus={errorMessage ? 'error' : 'success'}
              help={errorMessage}
            >
              <GoogleSearch
                text={fsaLocation?.name}
                queryText={`${fsaLocation?.name}, ${fsaLocation.formattedAddress}`}
              />
            </Form.Item>
            {!isNil(watch(`locations.${index}.fsaLocation.coordinates`)) && (
              <Button
                className="u-noPadding"
                type="link"
                onClick={() => centerMapToFsaLocation(fsaLocation)}
              >
                Map
              </Button>
            )}
          </>
        )
      },
    },
    {
      title: 'Google match',
      dataIndex: 'googleMatchRank',
      width: '100px',
      render: googleMatchRank =>
        googleMatchRank && (
          <LocationGoogleMatchRank matchRank={googleMatchRank} />
        ),
    },
    {
      title: 'Google name',
      width: '250px',
      render: (row, location, index) => {
        const googleLocation = get(
          getValues(),
          `locations.${index}.googleLocation`,
          location.googleLocation,
        )

        const { message: errorMessage } = get(
          errors,
          `locations.${index}.googleLocationId`,
          {},
        )
        return (
          <>
            <Form.Item
              style={{ margin: '0px' }}
              validateStatus={errorMessage ? 'error' : 'success'}
              help={errorMessage}
            >
              <GoogleSearch
                text={googleLocation?.name}
                queryText={`${googleLocation?.name} ${googleLocation?.formattedAddress}`}
              />
            </Form.Item>
            {!isNil(watch(`locations.${index}.googleLocationId`)) && (
              <Button
                className="u-noPadding"
                type="link"
                onClick={() =>
                  centerMapToGooglePlaceLocation(
                    watch(`locations.${index}.googleLocation`),
                  )
                }
              >
                Map
              </Button>
            )}
          </>
        )
      },
    },
    {
      title: 'No Google',
      dataIndex: 'isHasNoGoogleMatchConfirmed',
      width: '80px',
      render: (isHasNoGoogleMatchConfirmed, location, index) => (
        <Checkbox
          name={`locations.${index}.isHasNoGoogleMatchConfirmed`}
          disabled={!isNil(watch(`locations.${index}.googleLocationId`))}
          onCheckboxChange={() =>
            trigger(`locations.${index}.googleLocationId`)
          }
        />
      ),
    },
    {
      title: 'Address',
      width: '80px',
      render: (row, location, index) => (
        <LocationAddressDrawer location={locations[index]} />
      ),
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: '250px',
      ellipsis: true,
      render: website => (
        <Typography.Text>
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        </Typography.Text>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      width: '150px',
      render: phone => phone,
    },
    {
      title: 'Business status',
      dataIndex: 'businessStatus',
      width: '150px',
      render: businessStatus => BUSINESS_STATUSES[businessStatus]?.name,
    },
    {
      title: 'Duplicate of',
      width: '200px',
      render: (row, location, index) => (
        <DuplicateLocationSelect
          locationIndex={index}
          currentLocationId={location.id}
          locations={taskState.locations}
          onChange={() => trigger(`locations.${index}.googleLocationId`)}
        />
      ),
    },
    {
      render: (row, location, index) => (
        <HiddenInputs
          names={[
            `locations.${index}.id`,
            `locations.${index}.status`,
            `locations.${index}.website`,
            `locations.${index}.phone`,
            `locations.${index}.businessStatus`,
            `locations.${index}.exclusionReason`,
            `locations.${index}.duplicateOf.id`,
            `locations.${index}.duplicateOf.name`,
            `locations.${index}.fsaId`,
            `locations.${index}.fsaLocation.name`,
            `locations.${index}.fsaLocation.addressLine1`,
            `locations.${index}.fsaLocation.addressLine2`,
            `locations.${index}.fsaLocation.addressLine3`,
            `locations.${index}.fsaLocation.addressLine4`,
            `locations.${index}.fsaLocation.coordinates.latitude`,
            `locations.${index}.fsaLocation.coordinates.longitude`,
            `locations.${index}.fsaLocation.geocodeNorthEastCoordinates.latitude`,
            `locations.${index}.fsaLocation.geocodeNorthEastCoordinates.longitude`,
            `locations.${index}.fsaLocation.geocodeSouthWestCoordinates.latitude`,
            `locations.${index}.fsaLocation.geocodeSouthWestCoordinates.longitude`,
            `locations.${index}.googleLocationId`,
            `locations.${index}.googleMatchRank`,
            `locations.${index}.googleLocation.name`,
            `locations.${index}.googleLocation.phone`,
            `locations.${index}.googleLocation.streetNumber`,
            `locations.${index}.googleLocation.street`,
            `locations.${index}.googleLocation.city`,
            `locations.${index}.googleLocation.postCode`,
            `locations.${index}.googleLocation.formattedAddress`,
            `locations.${index}.googleLocation.coordinates.latitude`,
            `locations.${index}.googleLocation.coordinates.longitude`,
          ]}
        />
      ),
    },
  ]

  const onPageChange = useCallback(
    (page, pageSize) => {
      if (fsaRectangle) {
        fsaRectangle.setMap(null)
      }

      const formValues = getValues()
      if (isDirty && !isSubmitted) {
        Modal.confirm({
          title: 'Unsaved changes',
          content:
            'There are changes on the page that are not saved. Do you want to continue?',
          onOk() {
            const updatedTaskState = mergeWith(
              taskState,
              formValues,
              mergeFormValues,
            )

            setTaskState(updatedTaskState)
            const formState = getFormState(updatedTaskState, page, pageSize)

            const nextPageDisabledLocations = differenceBy(
              taskState.locations,
              formState.locations,
              'id',
            )

            setDisabledLocationsForMap(nextPageDisabledLocations)
            reset(formState)
            setCurrentPage(page)
            setCurrentPageSize(pageSize)
          },
        })
      } else {
        const updatedTaskState = mergeWith(
          taskState,
          formValues,
          mergeFormValues,
        )

        setTaskState(updatedTaskState)
        const formState = getFormState(updatedTaskState, page, pageSize)

        const nextPageDisabledLocations = differenceBy(
          taskState.locations,
          formState.locations,
          'id',
        )

        setDisabledLocationsForMap(nextPageDisabledLocations)
        reset(formState)
        setCurrentPage(page)
        setCurrentPageSize(pageSize)
      }
    },
    [
      fsaRectangle,
      getValues,
      taskState,
      reset,
      isDirty,
      isSubmitted,
      setCurrentPageSize,
    ],
  )

  const taskStatus = watch('task.status')
  const miscTaskVerifiedComment = watch('task.miscTaskVerifiedComment')
  const miscTaskQCVerifiedComment = watch('task.miscTaskQCVerifiedComment')

  const breadcrumbItems = useMemo(
    () => getBreadcrumbItemsForBrandDefinitionTaskPage(task),
    [task],
  )

  return (
    <>
      {(isAssigningTask ||
        isUnassigningTask ||
        isSavingTask ||
        isResolvingTask ||
        isMarkingTaskAsAwaiting ||
        isUnmarkingTaskAsAwaiting ||
        isReopeningTask ||
        isRefetchingTask) && <Spinner size="large" />}

      <Breadcrumb currentTaskType={BRAND_DEFINITION} items={breadcrumbItems} />

      <PageHeader sticky>
        <PageTitle>
          Brand definition task
          <HistoryDrawer
            entityType={BRAND_DEFINITION_TASK}
            entityId={task.id}
          />
          <QualityCheckedSwitch
            task={watch('task')}
            permissions={BRAND_DEFINITION_TASKS_PERMISSIONS}
            onChange={onQualityCheckChanged}
            loading={
              isMarkingTaskAsQualityChecked || isUnmarkingTaskAsQualityChecked
            }
          />
        </PageTitle>
        <PageActions>
          <TaskActionSection
            task={watch('task')}
            permissions={BRAND_DEFINITION_TASKS_PERMISSIONS}
            onAssignTask={onAssignTask}
            onUnassignTask={onUnassignTask}
            onSave={onSave}
            onDone={onDone}
            onMarkTaskAsAwaiting={onMarkTaskAsAwaiting}
            onUnmarkTaskAsAwaiting={onUnmarkTaskAsAwaiting}
            onReopenTask={onReopenTask}
            cancelUrl="/tasks/brand-definition"
          />
        </PageActions>
      </PageHeader>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Collapse defaultActiveKey={['brand-section', 'task-section']}>
            <Collapse.Panel
              header="Brand"
              key="brand-section"
              style={has(errors, 'brand') ? { border: '1px solid red' } : {}}
            >
              <Row gutter={16}>
                <Col span={2}>
                  <FormInternalLink
                    name="brand.id"
                    label="ID"
                    transform={id => `/brands/${id}`}
                  />
                </Col>
                <Col span={6}>
                  <Input name="brand.name" label="Name" />
                </Col>
                <Col span={8}>
                  <Input
                    name="brand.website"
                    label="Website"
                    disabled={watch('brand.isHasNoWebsiteConfirmed')}
                    addonAfter={
                      <OpenLink
                        onClick={() =>
                          !watch('brand.isHasNoWebsiteConfirmed') &&
                          window.open(getValues('brand.website'))
                        }
                      />
                    }
                  />
                </Col>
                <Col span={2}>
                  <Checkbox
                    name="brand.isHasNoWebsiteConfirmed"
                    label="No website"
                    onCheckboxChange={isHasNoWebsiteConfirmed => {
                      if (isHasNoWebsiteConfirmed) {
                        setCachedBrandWebsiteValue(getValues('brand.website'))
                        setValue('brand.website', null)
                      }
                      if (!isHasNoWebsiteConfirmed) {
                        setValue('brand.website', cachedBrandWebsiteValue)
                      }
                    }}
                  />
                </Col>
                <Col span={4}>
                  <Checkbox
                    name="brand.useBrandNameAsLocationName"
                    label="Use brand name as location name"
                  />
                </Col>
                <Col span={2}>
                  <FormText
                    name="brand.businessStatus"
                    label="Business status"
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={3}>
                  <FormDate name="brand.modifiedDate" label="Updated at" />
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Task" key="task-section">
              <Row gutter={16}>
                <Col span={3}>
                  <AwaitingReasonField
                    valueFieldName="task.awaitingReason"
                    titleFieldName="task.awaitingReasonComment"
                    label="Awaiting reason"
                  />
                </Col>
                <Col span={3}>
                  <FormDate label="Updated at" name="task.modifiedDate" />
                </Col>
                <VisibilityContainer isHidden={taskStatus !== REOPENED}>
                  <>
                    <Col span={3}>
                      <FormText
                        label="Reopened by"
                        name="task.reopenedBy.name"
                      />
                    </Col>
                    <Col span={3}>
                      <FormDate label="Reopened at" name="task.reopenedDate" />
                    </Col>
                    <Col span={5}>
                      <FormText
                        label="Reopen reason"
                        name="task.reopenReason"
                      />
                    </Col>
                  </>
                </VisibilityContainer>
                <Col span={3}>
                  <Form.Item label="Miscellaneous task">
                    <MiscTaskVerificationThreeStateSwitch
                      task={watch('task')}
                      onVerifiedButtonClick={onVerifiedButtonClick}
                      onQCVerifiedButtonClick={onQCVerifiedButtonClick}
                      loading={
                        isMarkingMiscTaskAsVerified ||
                        isMarkingMiscTaskAsQCVerified
                      }
                      loggedInUser={loggedInUser}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
          <BrandDefinitionMap
            mapSearchInputValue={taskState.brand.name}
            locations={locations}
            disabledLocations={disabledLocationsForMap}
            onMapInitialized={onMapInitialized}
            onGooglePlaceAttachedToLocation={attachGooglePlaceToLocation}
            onDetachGooglePlaceFromLocation={detachGooglePlaceFromLocation}
          />
          <Table
            rowKey="id"
            scroll={{ x: 1600 }}
            columns={tableColumns}
            dataSource={locations}
            pagination={false}
          />
          <Row type="flex" justify="end">
            <Pagination
              showSizeChanger
              current={currentPage}
              pageSize={currentPageSize}
              style={{ marginTop: '15px' }}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={size(taskState.locations)}
              onChange={onPageChange}
              onShowSizeChange={onPageChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
            />
          </Row>
          <HiddenInputs
            names={[
              'task.id',
              'task.assignedTo.id',
              'task.assignedTo.name',
              'task.status',
              'task.awaitingReason',
              'task.awaitingReasonComment',
              'task.qualityCheckedBy.name',
              'task.qualityCheckedDate',
              'task.reopenedDate',
              'task.reopenedBy.id',
              'task.reopenedBy.name',
              'task.reopenReason',
              'task.miscTaskVerifiedBy.id',
              'task.miscTaskVerifiedBy.name',
              'task.miscTaskVerifiedDate',
              'task.miscTaskVerifiedComment',
              'task.miscTaskQCVerifiedBy.id',
              'task.miscTaskQCVerifiedBy.name',
              'task.miscTaskQCVerifiedDate',
              'task.miscTaskQCVerifiedComment',
            ]}
          />
          <HiddenCheckboxes
            names={['task.isAssigned', 'task.isDone', 'task.isAwaiting']}
          />
        </form>
      </FormProvider>

      <MarkMiscTaskAsVerifiedModal
        isModalOpen={isMarkMiscTaskAsVerifiedModalOpen}
        isQCVerified={isQCVerified}
        comment={
          isQCVerified ? miscTaskQCVerifiedComment : miscTaskVerifiedComment
        }
        onSubmit={handleMarkMiscTaskAsVerified}
        closeModal={() => setIsMarkMiscTaskAsVerifiedModalOpen(false)}
      />
    </>
  )
}

BrandDefinitionTaskForm.propTypes = {
  task: PropTypes.object.isRequired,
  isSavingTask: PropTypes.bool.isRequired,
  isResolvingTask: PropTypes.bool.isRequired,
  isRefetchingTask: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default BrandDefinitionTaskForm
