import { gql } from 'apollo-boost'

const MENU_DEFINITION_TASK = gql`
  query menuDefinitionTask($id: ID!) {
    menuDefinitionTask(id: $id) {
      id
      status
      isAwaiting
      awaitingReason
      awaitingReasonComment
      qualityCheckedDate
      modifiedDate
      reopenedDate
      reopenReason
      brand {
        id
        name
        website
        status
        businessStatus
        menu {
          id
          url
          format
          additionalUrls
          availabilityComment
          isExample
          modifiedDate
          notScrapeableReason
          notScrapeableComment
        }
        locations {
          id
          name
          website
          businessStatus
          menuStatus
          formattedAddress
          menu {
            id
            url
            format
            additionalUrls
            availabilityComment
            isExample
            modifiedDate
            notScrapeableReason
            notScrapeableComment
            menuScrapingClusterId
          }
          fsaLocation {
            name
            addressLine1
            addressLine2
            addressLine3
            addressLine4
            postCode
          }
          googleLocation {
            name
            streetNumber
            street
            city
            postCode
          }
        }
      }
      assignedTo {
        id
        name
      }
      qualityCheckedBy {
        id
        name
      }
      reopenedBy {
        id
        name
      }
      qualityCheckedDate
      modifiedDate

      miscTaskVerifiedBy {
        id
        name
      }
      miscTaskVerifiedDate
      miscTaskVerifiedComment

      miscTaskQCVerifiedBy {
        id
        name
      }
      miscTaskQCVerifiedDate
      miscTaskQCVerifiedComment

      relatedTasks {
        brandId
        brandGatewayTask {
          id
          status
        }
        brandDefinitionTask {
          id
          status
        }
        menuDefinitionTask {
          id
          status
        }
      }
    }
  }
`

export default MENU_DEFINITION_TASK
