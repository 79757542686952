import { forEach } from 'lodash-es'

import { createUrl } from '../../core/utils/services/queryParams'

import {
  DISH_REVIEW_TASK_SORTING_CRITERIA,
  HTML,
  PARTIALLY_SCRAPED,
  SCRAPED,
} from '../../menus/constants'
import {
  DISH_REVIEW,
  MENU_SCRAPING,
  NOT_STARTED,
  REOPENED,
  TASK_TYPES,
} from '../../tasks/constants'
import { SORTING_ORDERS } from '../constants'

import { cacheFilterForPage } from '.'

const getFiltersByTaskType = taskType => {
  switch (taskType.value) {
    case DISH_REVIEW:
      return {
        statuses: [NOT_STARTED, REOPENED],
        menuFormats: [HTML],
        menuStatuses: [SCRAPED, PARTIALLY_SCRAPED],
        isAssigned: false,
        isVerified: true,
        sortingCriterion: DISH_REVIEW_TASK_SORTING_CRITERIA.MENU_SCRAPED_DATE,
        sortingOrder: SORTING_ORDERS.DESC,
      }
    case MENU_SCRAPING:
      return {
        statuses: [NOT_STARTED, REOPENED],
        menuFormats: [HTML],
        isAssigned: false,
        hasScrapingIssueComment: false,
        menuIsAwaitingProbing: false,
      }
    default:
      return {
        statuses: [NOT_STARTED, REOPENED],
        isAssigned: false,
      }
  }
}

const setTasksDefaultFilters = () =>
  forEach(TASK_TYPES, taskType => {
    const pageIdentifier = `/tasks/${taskType.key}`

    cacheFilterForPage(
      pageIdentifier,
      createUrl(pageIdentifier, getFiltersByTaskType(taskType)),
    )
  })

export default setTasksDefaultFilters
