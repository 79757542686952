import { gql } from 'apollo-boost'

const RESOLVE_MENU_SCRAPING_TASK_BY_RECRAWL_PDF_MENU = gql`
  mutation resolveMenuScrapingTaskByRecrawlPdfMenu(
    $id: ID!
    $useFallbackSettings: Boolean!
  ) {
    resolveMenuScrapingTaskByRecrawlPdfMenu(
      id: $id
      useFallbackSettings: $useFallbackSettings
    ) {
      id
    }
  }
`

export default RESOLVE_MENU_SCRAPING_TASK_BY_RECRAWL_PDF_MENU
