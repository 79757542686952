import React, { useCallback, useContext, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { yupResolver } from '@hookform/resolvers'
import {
  Col,
  Collapse,
  Form,
  Modal,
  Pagination,
  Row,
  Select as AntSelect,
  Table,
} from 'antd'
import {
  forEach,
  has,
  isEmpty,
  map,
  mergeWith,
  remove,
  size,
  slice,
  values,
} from 'lodash-es'
import PropTypes from 'prop-types'

import {
  Breadcrumb,
  Checkbox,
  FormDate,
  FormInternalLink,
  FormLink,
  FormText,
  HiddenCheckboxes,
  HiddenInputs,
  Input,
  Select,
} from '../../../core/components'
import { Button, Panel } from '../../../core/components/styled'
import {
  createUrl,
  getQueryParams,
} from '../../../core/utils/services/queryParams'

import {
  GoogleMapSearchByAddress,
  HistoryDrawer,
  LondonDateTime,
  OpenLink,
  Spinner,
  VisibilityContainer,
} from '../../../common/components'
import {
  PageActions,
  PageHeader,
  PageTitle,
} from '../../../common/components/styled'
import {
  DEFAULT_PAGE_SIZE,
  MENU_DEFINITION_TASK,
  MENU_DEFINITION_TASKS_PERMISSIONS,
} from '../../../common/constants'
import { PAGE_SIZE_OPTIONS } from '../../../common/constants/pagination'
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../common/helpers'

import { AuthContext } from '../../../account/contexts'
import {
  CLOSED_PERMANENTLY,
  CLOSED_TEMPORARILY,
  LOCATION_MENU_STATUSES,
  LOCATION_WITH_BRAND_MENU,
  LOCATION_WITH_NO_MENU,
  LOCATION_WITH_OWN_MENU,
} from '../../../brands/constants'
import {
  HTML,
  HTML_NOT_SCRAPEABLE,
  MENU_FORMATS,
  MENU_NOT_SCRAPEABLE_REASONS,
} from '../../../menus/constants'
import { isMenuScrapeable } from '../../../menus/helpers'
import {
  MANUAL_CHANGE,
  MENU_DEFINITION,
  REOPENED,
  TASK_AWAITING_REASONS,
  TASK_REOPEN_REASONS,
} from '../../constants'
import {
  ASSIGN_MENU_DEFINITION_TASK,
  MARK_MENU_DEFINITION_TASK_AS_AWAITING,
  MARK_MENU_DEFINITION_TASK_AS_QUALITY_CHECKED,
  MARK_MENU_DEFINITION_TASK_MISC_AS_QC_VERIFIED,
  MARK_MENU_DEFINITION_TASK_MISC_AS_VERIFIED,
  REOPEN_MENU_DEFINITION_TASK,
  UNASSIGN_MENU_DEFINITION_TASK,
  UNMARK_MENU_DEFINITION_TASK_AS_AWAITING,
  UNMARK_MENU_DEFINITION_TASK_AS_QUALITY_CHECKED,
} from '../../graphql/mutations'
import { getBreadcrumbItemsForMenuDefinitionTaskPage } from '../../helpers'
import { mergeFormValues } from '../../utils'
import {
  AdditionalMenuUrlsModal,
  AwaitingReasonField,
  MarkMiscTaskAsVerifiedModal,
  MiscTaskVerificationThreeStateSwitch,
  QualityCheckedSwitch,
  TaskActionSection,
} from '../pages/sections'

import { menuDefinitionTaskState } from './global-states'
import { menuDefinitionTaskSchema } from './schemas'
import { menuDefinitionTaskStateValidator } from './validators'

const getFormState = (taskState, page, pageSize = DEFAULT_PAGE_SIZE) => ({
  ...taskState,
  locations: slice(
    taskState.locations,
    (page - 1) * pageSize,
    (page - 1) * pageSize + pageSize,
  ),
})

const getMenuStatusOptions = hasBrandMenu => {
  const menuStatusOptions = map(LOCATION_MENU_STATUSES, ({ name, value }) => ({
    name,
    value,
  }))

  if (!hasBrandMenu)
    remove(
      menuStatusOptions,
      option => option.value === LOCATION_WITH_BRAND_MENU,
    )

  return menuStatusOptions
}

const MenuDefinitionTaskForm = ({
  task,
  isSavingTask,
  isResolvingTask,
  isRefetchingTask,
  onSubmit,
}) => {
  const { user: loggedInUser } = useContext(AuthContext)

  const taskStateCurrentValues = useMemo(() => menuDefinitionTaskState(task), [
    task,
  ])

  const [taskState, setTaskState] = useState(taskStateCurrentValues)
  const [currentPage, setCurrentPage] = useState(1)
  const [allMenusStatus, setAllMenusStatus] = useState()
  const [allMenusFormat, setAllMenusFormat] = useState()
  const [isQCVerified, setIsQCVerified] = useState(false)

  const [
    isMarkMiscTaskAsVerifiedModalOpen,
    setIsMarkMiscTaskAsVerifiedModalOpen,
  ] = useState(false)

  const [
    additionalMenuUrlModalSettings,
    setAdditionalMenuUrlModalSettings,
  ] = useState({ isOpen: false, menu: undefined, path: undefined })

  const history = useHistory()
  const { pathname, search } = useLocation()
  const { pageSize: currentPageSize = DEFAULT_PAGE_SIZE } = getQueryParams(
    search,
  )

  const setCurrentPageSize = useCallback(
    pageSize => history.push(createUrl(pathname, search, { pageSize })),
    [history, search, pathname],
  )

  const formMethods = useForm({
    defaultValues: getFormState(taskState, currentPage, currentPageSize),
    resolver: yupResolver(menuDefinitionTaskSchema),
  })

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    control,
    errors,
    formState: { isDirty, isSubmitted },
  } = formMethods

  const { fields: locations } = useFieldArray({
    control,
    name: 'locations',
  })

  const menuFormat = watch('brand.menu.format')
  const hasBrandMenu = watch('brand.hasMenu')
  const miscTaskVerifiedComment = watch('task.miscTaskVerifiedComment')
  const miscTaskQCVerifiedComment = watch('task.miscTaskQCVerifiedComment')

  const isScrapeable = useMemo(() => isMenuScrapeable(menuFormat), [menuFormat])

  const menuFormatOptions = useMemo(() => values(MENU_FORMATS), [])

  const notScrapableReasonOptions = useMemo(
    () => values(MENU_NOT_SCRAPEABLE_REASONS),
    [],
  )

  const [assignTask, { loading: isAssigningTask }] = useMutation(
    ASSIGN_MENU_DEFINITION_TASK,
    {
      onCompleted({ assignMenuDefinitionTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: assignMenuDefinitionTask.modifiedDate,
          isAssigned: true,
          assignedTo: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Menu definition task assigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu defintion task assign failed.',
          description: message,
        })
      },
    },
  )

  const [unassignTask, { loading: isUnassigningTask }] = useMutation(
    UNASSIGN_MENU_DEFINITION_TASK,
    {
      onCompleted({ unassignMenuDefinitionTask }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          modifiedDate: unassignMenuDefinitionTask.modifiedDate,
          isAssigned: false,
          assignedTo: {
            id: '',
            name: '',
          },
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Menu definition task unassigned.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu definition task unassign failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsAwaiting,
    { loading: isMarkingTaskAsAwaiting },
  ] = useMutation(MARK_MENU_DEFINITION_TASK_AS_AWAITING, {
    onCompleted({ markMenuDefinitionTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        isAwaiting: true,
        modifiedDate: markMenuDefinitionTaskAsAwaiting.modifiedDate,
        awaitingReason:
          TASK_AWAITING_REASONS[markMenuDefinitionTaskAsAwaiting.awaitingReason]
            .name,
        awaitingReasonComment:
          markMenuDefinitionTaskAsAwaiting.awaitingReasonComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu definition task marked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu definition task mark as awaiting failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsAwaiting,
    { loading: isUnmarkingTaskAsAwaiting },
  ] = useMutation(UNMARK_MENU_DEFINITION_TASK_AS_AWAITING, {
    onCompleted({ unmarkMenuDefinitionTaskAsAwaiting }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        modifiedDate: unmarkMenuDefinitionTaskAsAwaiting.modifiedDate,
        isAwaiting: false,
        awaitingReason: '',
        awaitingReasonComment: '',
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu definition task unmarked as awaiting.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu definition task unmark as awaiting failed.',
        description: message,
      })
    },
  })

  const [reopenTask, { loading: isReopeningTask }] = useMutation(
    REOPEN_MENU_DEFINITION_TASK,
    {
      onCompleted({
        reopenMenuDefinitionTask: { modifiedDate, reopenedDate },
      }) {
        const { task } = getValues()

        const updatedTask = {
          ...task,
          qualityCheckedBy: {
            name: null,
          },
          qualityCheckedDate: null,
          modifiedDate,
          reopenedBy: {
            id: loggedInUser.id,
            name: loggedInUser.name,
          },
          reopenedDate,
          reopenReason: TASK_REOPEN_REASONS[MANUAL_CHANGE].name,
          status: REOPENED,
        }

        setValue('task', updatedTask)

        showSuccessNotification({
          message: 'Menu definition task reopened.',
        })
      },
      onError({ message }) {
        showErrorNotification({
          message: 'Menu definition task reopen failed.',
          description: message,
        })
      },
    },
  )

  const [
    markTaskAsQualityChecked,
    { loading: isMarkingTaskAsQualityChecked },
  ] = useMutation(MARK_MENU_DEFINITION_TASK_AS_QUALITY_CHECKED, {
    onCompleted() {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        qualityCheckedBy: {
          name: loggedInUser.name,
        },
        qualityCheckedDate: new Date().toISOString(),
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu definition task marked as quality checked.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu definition task mark as quality checked failed.',
        description: message,
      })
    },
  })

  const [
    unmarkTaskAsQualityChecked,
    { loading: isUnmarkingTaskAsQualityChecked },
  ] = useMutation(UNMARK_MENU_DEFINITION_TASK_AS_QUALITY_CHECKED, {
    onCompleted() {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        qualityCheckedBy: {
          name: null,
        },
        qualityCheckedDate: null,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Menu definition task unmarked as quality checked.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Menu definition task unmark as quality checked failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsVerified,
    { loading: isMarkingMiscTaskAsVerified },
  ] = useMutation(MARK_MENU_DEFINITION_TASK_MISC_AS_VERIFIED, {
    onCompleted({
      markMenuDefinitionTaskMiscAsVerified: {
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskVerifiedBy: {
          id: loggedInUser.id,
          name: loggedInUser.name,
        },
        miscTaskVerifiedDate,
        miscTaskVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as verified failed.',
        description: message,
      })
    },
  })

  const [
    markMiscTaskAsQCVerified,
    { loading: isMarkingMiscTaskAsQCVerified },
  ] = useMutation(MARK_MENU_DEFINITION_TASK_MISC_AS_QC_VERIFIED, {
    onCompleted({
      markMenuDefinitionTaskMiscAsQCVerified: {
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      },
    }) {
      const { task } = getValues()

      const updatedTask = {
        ...task,
        miscTaskQCVerifiedBy: {
          id: loggedInUser.id,
          name: loggedInUser.name,
        },
        miscTaskQCVerifiedDate,
        miscTaskQCVerifiedComment,
      }

      setValue('task', updatedTask)

      showSuccessNotification({
        message: 'Miscellaneous task marked as QC verified.',
      })
    },
    onError({ message }) {
      showErrorNotification({
        message: 'Miscellaneous task mark as QC verified failed.',
        description: message,
      })
    },
  })

  const onAssignTask = useCallback(
    () =>
      assignTask({
        variables: { id: task.id, toUserId: loggedInUser.id },
      }),
    [assignTask, task, loggedInUser],
  )

  const onUnassignTask = useCallback(
    () => unassignTask({ variables: { id: task.id } }),
    [unassignTask, task],
  )

  const onSave = useCallback(() => {
    setValue('task.isDone', false)
    return handleSubmit(onSubmit)()
  }, [setValue, handleSubmit, onSubmit])

  const onDone = useCallback(() => {
    setValue('task.isDone', true)
    return handleSubmit(async formValues => {
      const updatedTaskState = mergeWith(taskState, formValues, mergeFormValues)
      const isValid = await menuDefinitionTaskStateValidator(updatedTaskState)

      if (isValid) {
        onSubmit(updatedTaskState)
      }
    })()
  }, [setValue, taskState, handleSubmit, onSubmit])

  const onMarkTaskAsAwaiting = useCallback(
    awaitingReasonData =>
      markTaskAsAwaiting({
        variables: { id: task.id, ...awaitingReasonData },
      }),
    [markTaskAsAwaiting, task],
  )

  const onUnmarkTaskAsAwaiting = useCallback(() => {
    unmarkTaskAsAwaiting({ variables: { id: task.id } })
  }, [unmarkTaskAsAwaiting, task])

  const onReopenTask = useCallback(
    () => reopenTask({ variables: { id: task.id } }),
    [reopenTask, task],
  )

  const onHasBrandMenuChange = useCallback(
    checked => {
      if (checked) {
        setValue('brand.menu.isExample', false)
        return
      }

      setValue('brand.menu.isExample', null)
      const { locations } = getValues()

      forEach(locations, ({ menuStatus }, index) => {
        if (menuStatus === LOCATION_WITH_BRAND_MENU)
          setValue(`locations.${index}.menuStatus`, LOCATION_WITH_NO_MENU)
      })

      const taskLocations = map(taskState.locations, location =>
        location.menuStatus === LOCATION_WITH_BRAND_MENU
          ? { ...location, menuStatus: LOCATION_WITH_NO_MENU }
          : location,
      )
      setTaskState({
        ...taskState,
        locations: taskLocations,
      })
    },

    [getValues, setValue, taskState],
  )

  const onSetAllMenusStatusChange = useCallback(
    menuStatus => {
      setAllMenusStatus(menuStatus)
      setAllMenusFormat()
      const { locations } = getValues()

      forEach(locations, (location, index) => {
        if (menuStatus === LOCATION_WITH_OWN_MENU) {
          setValue(`locations.${index}.menu.isExample`, false)
        }

        if (menuStatus !== LOCATION_WITH_OWN_MENU) {
          setValue(`locations.${index}.menu.isExample`, null)
        }

        setValue(`locations.${index}.menuStatus`, menuStatus, {
          shouldDirty: true,
          shouldValidate: true,
        })
      })
    },
    [getValues, setValue],
  )

  const onSetAllMenusFormatChange = useCallback(
    menuFormat => {
      setAllMenusFormat(menuFormat)

      const { locations } = getValues()

      forEach(locations, (location, index) => {
        if (location.menuStatus === LOCATION_WITH_OWN_MENU)
          setValue(`locations.${index}.menu.format`, menuFormat)
      })
    },
    [getValues, setValue],
  )

  const onLocationMenuStatusChange = useCallback(
    (menuStatus, locationIndex) => {
      setAllMenusStatus()
      setAllMenusFormat()

      if (menuStatus === LOCATION_WITH_OWN_MENU) {
        setValue(`locations.${locationIndex}.menu.isExample`, false)
      }

      if (menuStatus !== LOCATION_WITH_OWN_MENU) {
        setValue(`locations.${locationIndex}.menu.isExample`, null)
      }
    },
    [setValue],
  )

  const onBrandMenuFormatChange = useCallback(
    format => {
      if (isMenuScrapeable(format)) {
        setValue('brand.menu.notScrapeableReason', null)
      } else {
        setValue('brand.menu.additionalUrls', null)
      }
    },
    [setValue],
  )

  const onLocationMenuFormatChange = useCallback(
    (format, locationIndex) => {
      if (isMenuScrapeable(format)) {
        setValue(`locations.${locationIndex}.menu.notScrapeableReason`, null)
      } else {
        setValue(`locations.${locationIndex}.menu.additionalUrls`, null)
      }
    },
    [setValue],
  )

  const onSetBrandMenuForAllLocations = useCallback(() => {
    const locations = map(taskState.locations, location => ({
      ...location,
      menuStatus: LOCATION_WITH_BRAND_MENU,
    }))

    setTaskState(previousTaskState => ({
      ...previousTaskState,
      locations,
    }))

    forEach(locations, (location, index) =>
      setValue(`locations.${index}.menuStatus`, LOCATION_WITH_BRAND_MENU, {
        shouldDirty: true,
        shouldValidate: true,
      }),
    )
  }, [taskState, setValue])

  const onQualityCheckChanged = useCallback(
    isQualityChecked =>
      isQualityChecked
        ? markTaskAsQualityChecked({ variables: { id: task.id } })
        : unmarkTaskAsQualityChecked({ variables: { id: task.id } }),
    [markTaskAsQualityChecked, unmarkTaskAsQualityChecked, task],
  )

  const onVerifiedButtonClick = () => {
    setIsQCVerified(false)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const onQCVerifiedButtonClick = () => {
    setIsQCVerified(true)
    setIsMarkMiscTaskAsVerifiedModalOpen(true)
  }

  const handleMarkMiscTaskAsVerified = useCallback(
    ({ comment }) => {
      const data = { variables: { id: task.id, comment } }
      setIsMarkMiscTaskAsVerifiedModalOpen(false)

      return isQCVerified
        ? markMiscTaskAsQCVerified(data)
        : markMiscTaskAsVerified(data)
    },
    [isQCVerified, markMiscTaskAsVerified, markMiscTaskAsQCVerified, task],
  )

  const onPageChange = useCallback(
    (page, pageSize) => {
      setAllMenusFormat()
      setAllMenusStatus()
      if (isDirty && !isSubmitted) {
        Modal.confirm({
          title: 'Unsaved changes',
          content:
            'There are changes on the page that are not saved. Do you want to continue?',
          onOk() {
            const formValues = getValues()
            const updatedTaskState = mergeWith(
              taskState,
              formValues,
              mergeFormValues,
            )

            setTaskState(updatedTaskState)
            const formState = getFormState(updatedTaskState, page, pageSize)

            reset(formState)
            setCurrentPage(page)
            setCurrentPageSize(pageSize)
          },
        })
      } else {
        const formValues = getValues()
        const updatedTaskState = mergeWith(
          taskState,
          formValues,
          mergeFormValues,
        )

        setTaskState(updatedTaskState)
        const formState = getFormState(updatedTaskState, page, pageSize)
        reset(formState)
        setCurrentPage(page)
        setCurrentPageSize(pageSize)
      }
    },
    [getValues, taskState, reset, isDirty, isSubmitted, setCurrentPageSize],
  )

  const taskStatus = watch('task.status')

  const breadcrumbItems = useMemo(
    () => getBreadcrumbItemsForMenuDefinitionTaskPage(task),
    [task],
  )

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '80px',
      render: (id, location, index) => (
        <FormInternalLink
          name={`locations.${index}.id`}
          transform={id => `/brands/locations/${id}`}
        />
      ),
    },
    {
      title: 'Location name',
      dataIndex: 'name',
      width: '150px',
      render: (id, location, index) => (
        <Panel padding="sMedium no no">
          <FormText name={`locations.${index}.name`} />
        </Panel>
      ),
    },
    {
      title: 'Address',
      width: '200px',
      render: location => <GoogleMapSearchByAddress location={location} />,
    },
    {
      title: () => (
        <Row gutter={16}>
          <Col span={11}>
            <span className="ant-table-column-title">Menu status</span>
          </Col>
          <Col span={11}>
            <AntSelect
              style={{ width: '120px' }}
              onChange={onSetAllMenusStatusChange}
              value={allMenusStatus}
            >
              {map(getMenuStatusOptions(hasBrandMenu), ({ name, value }) => (
                <AntSelect.Option key={value} value={value}>
                  {name}
                </AntSelect.Option>
              ))}
            </AntSelect>
          </Col>
        </Row>
      ),
      width: '250px',
      render: (row, location, index) => (
        <Select
          name={`locations.${index}.menuStatus`}
          options={getMenuStatusOptions(hasBrandMenu)}
          onSelectChange={menuStatus =>
            onLocationMenuStatusChange(menuStatus, index)
          }
        />
      ),
    },
    {
      title: 'Website',
      dataIndex: 'website',
      width: '200px',
      ellipsis: true,
      render: website =>
        website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        ),
    },
    {
      title: 'Scraping Cluster ID',
      width: '100px',
      render: (row, location, index) => {
        const menuScrapingClusterId = location.menu?.menuScrapingClusterId

        return (
          menuScrapingClusterId && (
            <FormInternalLink
              name={`locations.${index}.menu.menuScrapingClusterId`}
              transform={menuScrapingClusterId =>
                `/menu-scraping-templates/clusters/${menuScrapingClusterId}`
              }
            />
          )
        )
      },
    },
    {
      title: 'Menu URL',
      width: '250px',
      render: (row, location, index) => {
        const hasLocationOwnMenu =
          watch(`locations.${index}.menuStatus`) === LOCATION_WITH_OWN_MENU

        return (
          hasLocationOwnMenu && (
            <Input
              name={`locations.${index}.menu.url`}
              addonAfter={
                <OpenLink
                  onClick={() =>
                    window.open(getValues(`locations.${index}.menu.url`))
                  }
                />
              }
            />
          )
        )
      },
    },
    {
      title: () => (
        <Row gutter={16}>
          <Col span={11}>
            <span className="ant-table-column-title">Menu format</span>
          </Col>
          <Col span={11}>
            <AntSelect
              style={{ width: '120px' }}
              onChange={onSetAllMenusFormatChange}
              value={allMenusFormat}
            >
              {map(MENU_FORMATS, ({ name, value }) => (
                <AntSelect.Option key={value} value={value}>
                  {name}
                </AntSelect.Option>
              ))}
            </AntSelect>
          </Col>
        </Row>
      ),
      width: '250px',
      render: (row, location, index) => {
        const hasLocationOwnMenu =
          watch(`locations.${index}.menuStatus`) === LOCATION_WITH_OWN_MENU

        const hasLocationHTMLMenu =
          watch(`locations.${index}.menu.format`) === HTML

        return (
          hasLocationOwnMenu && (
            <>
              <Select
                name={`locations.${index}.menu.format`}
                options={menuFormatOptions}
                onSelectChange={format =>
                  onLocationMenuFormatChange(format, index)
                }
              />
              {hasLocationHTMLMenu && (
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() =>
                    setAdditionalMenuUrlModalSettings({
                      isOpen: true,
                      menu: getValues().locations[index].menu,
                      path: `locations.${index}.menu`,
                    })
                  }
                >
                  Add additional links
                </Button>
              )}
            </>
          )
        )
      },
    },
    {
      title: 'Not scrapeable reason',
      width: '160px',
      render: (row, location, index) => {
        const hasLocationOwnMenu =
          watch(`locations.${index}.menuStatus`) === LOCATION_WITH_OWN_MENU

        const hasLocationMenuNotScrapeable =
          watch(`locations.${index}.menu.format`) === HTML_NOT_SCRAPEABLE

        return (
          hasLocationOwnMenu &&
          hasLocationMenuNotScrapeable && (
            <Select
              name={`locations.${index}.menu.notScrapeableReason`}
              options={notScrapableReasonOptions}
            />
          )
        )
      },
    },
    {
      title: 'Not scrapeable comment',
      width: '220px',
      render: (row, location, index) => {
        const hasLocationOwnMenu =
          watch(`locations.${index}.menuStatus`) === LOCATION_WITH_OWN_MENU

        const hasLocationMenuNotScrapeable =
          watch(`locations.${index}.menu.format`) === HTML_NOT_SCRAPEABLE

        return (
          hasLocationOwnMenu &&
          hasLocationMenuNotScrapeable && (
            <FormText name={`locations.${index}.menu.notScrapeableComment`} />
          )
        )
      },
    },
    {
      title: 'Example menu',
      width: '80px',
      render: (row, location, index) => {
        const hasLocationOwnMenu =
          watch(`locations.${index}.menuStatus`) === LOCATION_WITH_OWN_MENU

        return (
          <VisibilityContainer isHidden={!hasLocationOwnMenu}>
            <Checkbox name={`locations.${index}.menu.isExample`} />
          </VisibilityContainer>
        )
      },
    },
    {
      title: 'Availability comment',
      width: '160px',
      render: (row, location, index) => {
        const hasLocationOwnMenu =
          watch(`locations.${index}.menuStatus`) === LOCATION_WITH_OWN_MENU

        const hasLocationMenuNotScrapeable =
          watch(`locations.${index}.menu.format`) === HTML_NOT_SCRAPEABLE

        return (
          <VisibilityContainer
            isHidden={!hasLocationOwnMenu && hasLocationMenuNotScrapeable}
          >
            <Input name={`locations.${index}.menu.availabilityComment`} />
          </VisibilityContainer>
        )
      },
    },

    {
      title: 'Menu updated At',
      dataIndex: 'menu.modifiedDate',
      width: '160px',
      render: date => <LondonDateTime date={date} />,
    },
    {
      width: '0px',
      render: (row, location, index) => (
        <>
          <HiddenInputs
            names={[
              `brand.menu.id`,
              `brand.menu.additionalUrls`,

              `locations.${index}.menu.id`,
              `locations.${index}.menu.additionalUrls`,
            ]}
          />
        </>
      ),
    },
  ]

  return (
    <>
      {(isAssigningTask ||
        isUnassigningTask ||
        isSavingTask ||
        isResolvingTask ||
        isMarkingTaskAsAwaiting ||
        isUnmarkingTaskAsAwaiting ||
        isReopeningTask ||
        isRefetchingTask) && <Spinner size="large" />}

      <Breadcrumb currentTaskType={MENU_DEFINITION} items={breadcrumbItems} />

      <PageHeader sticky>
        <PageTitle>
          Menu definition task
          <HistoryDrawer entityType={MENU_DEFINITION_TASK} entityId={task.id} />
          <QualityCheckedSwitch
            task={watch('task')}
            permissions={MENU_DEFINITION_TASKS_PERMISSIONS}
            onChange={onQualityCheckChanged}
            loading={
              isMarkingTaskAsQualityChecked || isUnmarkingTaskAsQualityChecked
            }
          />
        </PageTitle>

        <PageActions>
          <TaskActionSection
            task={watch('task')}
            permissions={MENU_DEFINITION_TASKS_PERMISSIONS}
            onAssignTask={onAssignTask}
            onUnassignTask={onUnassignTask}
            onSave={onSave}
            onDone={onDone}
            onMarkTaskAsAwaiting={onMarkTaskAsAwaiting}
            onUnmarkTaskAsAwaiting={onUnmarkTaskAsAwaiting}
            onReopenTask={onReopenTask}
            cancelUrl="/tasks/menu-definition"
            reset={reset}
          />
        </PageActions>
      </PageHeader>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Collapse defaultActiveKey={['brand-section', 'task-section']}>
            <Collapse.Panel
              header="Brand"
              key="brand-section"
              style={has(errors, 'brand') ? { border: '1px solid red' } : {}}
            >
              <Row gutter={16}>
                <Col span={2}>
                  <FormInternalLink
                    transform={() => `/brands/${task.brand.id}`}
                    name="brand.id"
                    label="ID"
                  />
                </Col>
                <Col span={5}>
                  <FormText name="brand.name" label="Name" />
                </Col>
                <Col span={5}>
                  <FormLink name="brand.website" label="Website" />
                </Col>
                <Col span={2}>
                  <Checkbox
                    name="brand.hasMenu"
                    label="Has menu"
                    onCheckboxChange={onHasBrandMenuChange}
                  />
                </Col>
                <VisibilityContainer isHidden={!hasBrandMenu}>
                  <Col span={3}>
                    <Checkbox
                      name="brand.menu.isExample"
                      label="Example menu"
                    />
                  </Col>
                  <Col span={7}>
                    <Input
                      name="brand.menu.url"
                      label="Menu URL"
                      addonAfter={
                        <OpenLink
                          onClick={() =>
                            window.open(getValues('brand.menu.url'))
                          }
                        />
                      }
                    />
                  </Col>
                </VisibilityContainer>
              </Row>
              <Row gutter={16}>
                <VisibilityContainer isHidden={!hasBrandMenu}>
                  <Col span={4}>
                    <Select
                      style={{ width: '80%' }}
                      label="Menu format"
                      name="brand.menu.format"
                      options={menuFormatOptions}
                      onSelectChange={onBrandMenuFormatChange}
                    />
                    {isScrapeable && (
                      <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() =>
                          setAdditionalMenuUrlModalSettings({
                            isOpen: true,
                            menu: getValues().brand.menu,
                            path: 'brand.menu',
                          })
                        }
                      >
                        Add additional links
                      </Button>
                    )}
                  </Col>
                  {watch('brand.menu.format') === HTML_NOT_SCRAPEABLE && (
                    <Col span={4}>
                      <Select
                        name="brand.menu.notScrapeableReason"
                        label="Not scrapeable reason"
                        options={notScrapableReasonOptions}
                      />
                    </Col>
                  )}
                </VisibilityContainer>
                {isScrapeable && (
                  <Col span={5}>
                    <Input
                      style={{ width: '80%' }}
                      name="brand.menu.availabilityComment"
                      label="Availability comment"
                    />
                  </Col>
                )}
                <Col span={3}>
                  <FormDate
                    name="brand.menu.modifiedDate"
                    label="Menu updated at"
                  />
                </Col>
              </Row>
              {watch('brand.menu.format') === HTML_NOT_SCRAPEABLE && (
                <Row gutter={16}>
                  <Col span={8}>
                    <FormText
                      style={{ width: '100%' }}
                      label="Not scrapeable comment"
                      name="brand.menu.notScrapeableComment"
                    />
                  </Col>
                </Row>
              )}
            </Collapse.Panel>
            <Collapse.Panel header="Task" key="task-section">
              <Row gutter={16}>
                <Col span={4}>
                  <AwaitingReasonField
                    valueFieldName="task.awaitingReason"
                    titleFieldName="task.awaitingReasonComment"
                    label="Awaiting reason"
                  />
                </Col>
                <Col span={3}>
                  <FormDate label="Updated at" name="task.modifiedDate" />
                </Col>
                <VisibilityContainer isHidden={taskStatus !== REOPENED}>
                  <>
                    <Col span={3}>
                      <FormText
                        label="Reopened by"
                        name="task.reopenedBy.name"
                      />
                    </Col>
                    <Col span={3}>
                      <FormDate label="Reopened at" name="task.reopenedDate" />
                    </Col>
                    <Col span={4}>
                      <FormText
                        label="Reopen reason"
                        name="task.reopenReason"
                      />
                    </Col>
                  </>
                </VisibilityContainer>
                <Col span={3}>
                  <Form.Item label="Miscellaneous task">
                    <MiscTaskVerificationThreeStateSwitch
                      task={watch('task')}
                      onVerifiedButtonClick={onVerifiedButtonClick}
                      onQCVerifiedButtonClick={onQCVerifiedButtonClick}
                      loading={
                        isMarkingMiscTaskAsVerified ||
                        isMarkingMiscTaskAsQCVerified
                      }
                      loggedInUser={loggedInUser}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>

          <Button
            style={{ margin: '5px 0px' }}
            disabled={!hasBrandMenu}
            onClick={() => onSetBrandMenuForAllLocations()}
          >
            Set brand menu for all locations
          </Button>

          <Table
            rowKey="id"
            scroll={{ x: 1900 }}
            columns={tableColumns}
            dataSource={locations}
            rowClassName={record =>
              record.busisnessStatus === CLOSED_TEMPORARILY ||
              record.busisnessStatus === CLOSED_PERMANENTLY
                ? 'closed'
                : undefined
            }
            pagination={false}
          />
          <Row type="flex" justify="end">
            <Pagination
              showSizeChanger
              current={currentPage}
              pageSize={currentPageSize}
              style={{ marginTop: '15px' }}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              total={size(taskState.locations)}
              onChange={onPageChange}
              onShowSizeChange={onPageChange}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
            />
          </Row>
          <HiddenInputs
            names={[
              'task.id',
              'task.assignedTo.id',
              'task.assignedTo.name',
              'task.status',
              'task.awaitingReason',
              'task.awaitingReasonComment',
              'task.qualityCheckedBy.name',
              'task.qualityCheckedDate',
              'task.miscTaskVerifiedBy.id',
              'task.miscTaskVerifiedBy.name',
              'task.miscTaskVerifiedDate',
              'task.miscTaskVerifiedComment',
              'task.miscTaskQCVerifiedBy.id',
              'task.miscTaskQCVerifiedBy.name',
              'task.miscTaskQCVerifiedDate',
              'task.miscTaskQCVerifiedComment',
            ]}
          />
          <HiddenCheckboxes
            names={['task.isAssigned', 'task.isDone', 'task.isAwaiting']}
          />
        </form>
      </FormProvider>

      <AdditionalMenuUrlsModal
        isModalOpen={additionalMenuUrlModalSettings.isOpen}
        menu={additionalMenuUrlModalSettings.menu}
        onSave={additionalUrls => {
          if (isEmpty(additionalUrls)) {
            setValue(
              `${additionalMenuUrlModalSettings.path}.additionalUrls`,
              '',
            )
          } else {
            forEach(additionalUrls, (additionalUrl, index) =>
              setValue(
                `${additionalMenuUrlModalSettings.path}.${index}`,
                additionalUrl,
              ),
            )
          }

          setAdditionalMenuUrlModalSettings({
            isOpen: false,
            menu: undefined,
            path: undefined,
          })
        }}
        onCancel={() =>
          setAdditionalMenuUrlModalSettings({
            isOpen: false,
            menu: undefined,
            path: undefined,
          })
        }
      />

      <MarkMiscTaskAsVerifiedModal
        isModalOpen={isMarkMiscTaskAsVerifiedModalOpen}
        isQCVerified={isQCVerified}
        comment={
          isQCVerified ? miscTaskQCVerifiedComment : miscTaskVerifiedComment
        }
        onSubmit={handleMarkMiscTaskAsVerified}
        closeModal={() => setIsMarkMiscTaskAsVerifiedModalOpen(false)}
      />
    </>
  )
}

MenuDefinitionTaskForm.propTypes = {
  task: PropTypes.object.isRequired,
  isSavingTask: PropTypes.bool.isRequired,
  isResolvingTask: PropTypes.bool.isRequired,
  isRefetchingTask: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default MenuDefinitionTaskForm
