import { gql } from 'apollo-boost'

const MENU_SCRAPING_TASK = gql`
  query menuScrapingTask($id: ID!) {
    menuScrapingTask(id: $id) {
      id
      status
      isAwaiting
      isRerunFlow
      awaitingReason
      awaitingReasonComment
      verifiedDate
      isVerifiedInDebugMode
      modifiedDate
      reopenedDate
      reopenReason
      menu {
        id
        url
        scrapingInstructions
        scrapingInstructionsVersionNumber
        notScrapeableReason
        notScrapeableComment
        scrapingRejectedBy {
          id
          name
        }
        scrapingRejectedDate
        scrapingRejectedReason
        scrapingRejectedComment
        crawlingRejectedBy {
          id
          name
        }
        crawlingRejectedDate
        crawlingRejectedReason
        crawlingRejectedComment
        status
        format
        isAwaitingScraping
        isHtmlMenuScrapedInDebugMode
        isDeleted
        htmlMenuScrapingJobId
        menuScrapingClusterId
        menuScrapingTemplateId
        brand {
          id
          name
        }
        locations {
          id
          name
          formattedAddress
        }
        dishReviewTask {
          id
        }
        scrapingSummary {
          menuAnnotations {
            fullMenu
          }
          messages
          status
          scrapingReport {
            fileKey
          }
        }
        modifiedDate
        scrapingIssueComment
        isAwaitingProbing
        pdfFiles {
          id
          fileText
          fileName
          fileBucketName
          fileKey
          isSelected
          menuCategory
        }
        menuCrawlingJob {
          id
          crawlingStatus
          metadata
          menuCrawlingResponse {
            data
          }
        }
      }
      assignedTo {
        id
        name
      }
      resolvedBy {
        id
        name
      }
      verifiedBy {
        id
        name
      }
      reopenedBy {
        id
        name
      }
      verifiedDate
      isVerifiedInDebugMode
      modifiedDate

      miscTaskVerifiedBy {
        id
        name
      }
      miscTaskVerifiedDate
      miscTaskVerifiedComment

      miscTaskQCVerifiedBy {
        id
        name
      }
      miscTaskQCVerifiedDate
      miscTaskQCVerifiedComment

      relatedTasks {
        brandId
        brandGatewayTask {
          id
          status
        }
        brandDefinitionTask {
          id
          status
        }
        menuDefinitionTask {
          id
          status
        }
        menuScrapingTask {
          id
          status
        }
        dishReviewTask {
          id
          status
        }
      }
    }
  }
`

export default MENU_SCRAPING_TASK
