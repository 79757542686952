import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Row } from 'antd'
import PropTypes from 'prop-types'

import { TextArea } from '../../../core/components'
import { Button } from '../../../core/components/styled'

import { markMiscTaskAsVerifiedFormInitialValues } from './initial-values'

const MarkMiscTaskAsVerifiedForm = ({ comment, onSubmit, onClose }) => {
  const formMethods = useForm({
    defaultValues: markMiscTaskAsVerifiedFormInitialValues(comment),
  })

  const handleSave = data => {
    onSubmit(data)
  }

  const handleCancel = () => {
    onClose()
  }

  useEffect(() => {
    formMethods.setValue('comment', comment)
  }, [comment, formMethods])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSave)}>
        <Row>
          <TextArea name="comment" label="Comment" autoSize />
        </Row>
        <Row type="flex" justify="end">
          <Button onClick={handleCancel} margin="small small no no">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" margin="small no no no">
            Save
          </Button>
        </Row>
      </form>
    </FormProvider>
  )
}

MarkMiscTaskAsVerifiedForm.propTypes = {
  comment: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

MarkMiscTaskAsVerifiedForm.defaultProps = {
  comment: '',
}

export default MarkMiscTaskAsVerifiedForm
